import {
  AgentGetById,
  CountByAgent,
  GetAllHandlersByAgent,
} from "functions/http-requests/agents";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toggleLoading } from "state/reducers/loading";
import AgentAnalytics from "./agentAnalytics";
import { AppStatusCode } from "config/appStatusCode";
import AgentDetailsTable from "./agentDetailsTable";
import { Box, Card, Grid, Typography } from "@mui/material";
import { GetAllHandlersByAgentProps } from "_interfaces/functions/http-requests/agents";
import { filterNonNullValues } from "functions/helper";
import { GridPaginationModel } from "@mui/x-data-grid";
import { GetAllCustomersByAgentProps } from "_interfaces/functions/http-requests/customer-management";
import { GetCustomerByAgentId } from "functions/http-requests/customer-management";
import moment from "moment";
import CustomerManagementTableInterface from "_interfaces/customer-management/customerManagementTable";
import CustomerManagementFilter from "component/customer-management/customerManagementFilter";
import CustomerManagementTable from "component/customer-management/customerManagementTable";
import SectionSearch from "parts/sectionSearch";
import {
  CartonListByDistributor,
  DistributorDetailsAnalytics,
} from "_interfaces/regional-distributors/details/regionalDistributorDetailsTable";
import HandlersTable from "./handlersTable";
import HandlerDetailsFilter from "./handlerDetailsFilter";
import { HandlersFilterModel } from "_models/data/handler/data.handlers-filter.model";
import { AgentDetailsInterface } from "_interfaces/agent";
import CartonsTable from "component/regional-distributors/details/cartonsTable";
import { GetCartonByAgent } from "functions/http-requests/carton";
import { useSearchParams } from "react-router-dom";

interface Props {
  ID: string;
}

const AgentDeailsLayout: React.FC<Props> = ({ ID }) => {
  const isInitialRender = useRef(true);
  const isInitialHandlerRender = useRef(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [handlerSearchParams, setHandlerSearchParams] = useSearchParams();
  const [analyticsData, setAnalyticsData] = useState<
    DistributorDetailsAnalytics[]
  >([]);
  const Dispatch = useDispatch();
  const [agentDetails, setAgentDetails] =
    useState<AgentDetailsInterface | null>(null);
  const [handlersData, setHandlersData] = useState<any[]>([]);
  const [handlerRefresh, setHandlerRefresh] = useState<boolean>(false);
  const [handlerSearchKey, setHandlerSearchKey] = useState<string>(
    handlerSearchParams.get("handlerSearch") || ""
  );
  const handleAgentRefresh = () => setHandlerRefresh(!handlerRefresh);
  const [openHandlerFilter, setOpenHandlerFilter] = useState<boolean>(false);
  const [handlerFilters, setHandlerFilters] = useState<any>({
    activeStatus: handlerSearchParams.get("activeStatus") || "",
    approveStatus: handlerSearchParams.get("approveStatus") || "",
  });
  const [handlerPaginationModel, setHandlerPaginationModel] =
    useState<GridPaginationModel>({
      page: Number(searchParams.get("handlerPage")) || 0,
      pageSize: Number(searchParams.get("handlerPageSize")) || 10,
    });
  const [totalHandlercount, setTotalHandlerCount] = useState<number>(0);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [handlersDataLoading, setHandlersDataLoading] =
    useState<boolean>(false);
  const [customerFilters, setCustomerFilters] = useState<any>({
    kycStatus: searchParams.get("kycStatus") || "",
    serviceStatus: searchParams.get("serviceStatus") || "",
    customerType: searchParams.get("customerType") || "",
    identityType: searchParams.get("identityType") || "",
    fromDate: searchParams.get("fromDate") || null,
    toDate: searchParams.get("toDate") || null,
  });
  const [refresh, setRefresh] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>(
    searchParams.get("search") || ""
  );
  const handleRefresh = () => setRefresh(!refresh);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [userId, setUserId] = useState<string | null>(null);
  const [customerPaginationModel, setCustomerPaginationModel] =
    useState<GridPaginationModel>({
      page: Number(searchParams.get("page")) || 0,
      pageSize: Number(searchParams.get("pageSize")) || 10,
    });
  const [data, setData] = useState<CustomerManagementTableInterface["data"]>(
    []
  );
  const [totalCustomercount, setTotalCustomerCount] = useState<number>(0);
  const [cartons, setCartons] = useState<CartonListByDistributor[]>([]);
  const [cartonLoading, setCartonLoading] = useState<boolean>(false);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchKey = e.target.value;
    setSearchKey(newSearchKey);
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);
      updatedParams.set("search", newSearchKey);
      return updatedParams;
    });
  };

  const handleSubmitSearch = () => {
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);
      updatedParams.set("search", searchKey);
      updatedParams.set("page", "0"); // Reset to the first page on search submit
      updatedParams.set("pageSize", `${customerPaginationModel.pageSize}`);
      return updatedParams;
    });
    isInitialRender.current = false;
    handleRefresh();
  };
  const handleHandlerSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchKey = e.target.value;
    setHandlerSearchKey(newSearchKey);
    setHandlerSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);
      updatedParams.set("handlerSearch", newSearchKey);
      updatedParams.set("urlSearchType", "handler");
      return updatedParams;
    });
  };

  const toggleFilter = () => {
    setOpenFilter(!openFilter);
  };
  const toggleHandlerFilter = () => {
    setOpenHandlerFilter(!openHandlerFilter);
  };

  const fetchCartons = () => {
    setCartonLoading(true);
    GetCartonByAgent(ID)
      .then((res) => {
        const data = res?.data;
        if (data?.statusCode === AppStatusCode.api_success) {
          let DATA: any = data?.data;
          DATA = DATA.map((item: any) => ({
            id: item._id,
            firstICCID: item?.firstICCID,
            lastICCID: item?.lastICCID,
            batchNo: item?.batchNo,
            boxNo: item?.boxNo,
            quantity: item?.quantity,
            date: item?.date,
            SIM_type: item?.SIM_type,
            serviceType: item?.serviceType,
            regDistributor: item?.regDistributor,
          }));
          setCartons(DATA);
        }
      })
      .catch((error) => {
        setCartons([]);
      })
      .finally(() => setCartonLoading(false));
  };

  const handleClearSearch = () => {
    setSearchKey("");
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);
      // Remove the 'searchKey' parameter from the URL if it exists
      updatedParams.delete("search");
      return updatedParams;
    });
  };

  const handleClearHandlerSearch = () => {
    setHandlerSearchKey("");
    setHandlerSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);
      // Remove the 'searchKey' parameter from the URL if it exists
      updatedParams.delete("handlerSearch");
      return updatedParams;
    });
  };

  const handleHandlerSubmitSearch = () => {
    setHandlerSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);
      updatedParams.set("agentSearch", searchKey);
      updatedParams.set("page", "0"); // Reset to the first page on search submit
      updatedParams.set("pageSize", `${handlerPaginationModel.pageSize}`);
      return updatedParams;
    });
    isInitialRender.current = false;
    handleAgentRefresh();
  };

  const fetchAgentAnalytics = () => {
    Dispatch(toggleLoading(true));
    CountByAgent(ID)
      .then((res) => setAnalyticsData(res?.data?.data))
      .catch((err) => setAnalyticsData([]))
      .finally(() => Dispatch(toggleLoading(false)));
  };

  const fetchAgentDetails = () => {
    Dispatch(toggleLoading(true));
    AgentGetById(ID)
      .then((res) => {
        const data = res?.data;
        if (data?.statusCode === AppStatusCode.api_success) {
          let DATA: any = data?.data;
          setUserId(DATA?.userDetails?._id);
          setAgentDetails({
            id: DATA?._id,
            email: DATA?.userDetails?.email,
            name: DATA?.userDetails?.name,
            phone: DATA?.userDetails?.phone,
            alternativePhoneNumber: DATA?.phone,
            isActive: DATA?.isActive,
            isApprove: DATA?.isApproved,
            role: DATA?.userDetails?.role,
            county: DATA?.county,
            msisdn: DATA?.agentMSISDN,
            preferredLanguage: DATA?.preferredLanguage,
            type: DATA?.type,
            subCounty: DATA?.subCounty,
            date: DATA?.createdAt,
            assignedLocation: [
              {
                region: DATA?.assignedLocation[0]?.region,
                districts: DATA?.assignedLocation[0]?.districts,
              },
            ],
          });
        }
      })
      .catch((error) => {
        setAgentDetails(null);
      })
      .finally(() => Dispatch(toggleLoading(false)));
  };

  //for agent----------------------
  useEffect(() => {
    const search = handlerSearchParams.get("handlerSearch") || "";
    setHandlerSearchKey(search);
  }, [handlerSearchParams]);

  useEffect(() => {
    setHandlerSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);

      updatedParams.set("handlerPage", `${handlerPaginationModel.page}`);
      updatedParams.set(
        "handlerPageSize",
        `${handlerPaginationModel.pageSize}`
      );

      // If the filters object is empty, clear all filter parameters from the URL
      if (Object.keys(handlerFilters).length === 0) {
        // Remove all filter-related keys from the URL
        Array.from(updatedParams.keys()).forEach((key) => {
          if (
            key !== "handlerSearch" &&
            key !== "handlerPage" &&
            key !== "handlerPageSize"
          ) {
            updatedParams.delete(key);
          }
        });
      } else {
        // Update filters in the URL
        Object.entries(handlerFilters).forEach(([key, value]) => {
          if (value) {
            updatedParams.set(key, String(value));
          } else {
            updatedParams.delete(key);
          }
        });
      }

      return updatedParams;
    });
  }, [handlerFilters, handlerPaginationModel, setHandlerSearchParams]);

  //for customer------------------------
  useEffect(() => {
    const search = searchParams.get("search") || "";
    setSearchKey(search);
  }, [searchParams]);

  useEffect(() => {
    const search = handlerSearchParams.get("urlSearchType") || "";
    if (search) {
      return;
    }
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);

      updatedParams.set("page", `${customerPaginationModel.page}`);
      updatedParams.set("pageSize", `${customerPaginationModel.pageSize}`);

      // If the filters object is empty, clear all filter parameters from the URL
      if (Object.keys(customerFilters).length === 0) {
        // Remove all filter-related keys from the URL
        Array.from(updatedParams.keys()).forEach((key) => {
          if (key !== "search" && key !== "page" && key !== "pageSize") {
            updatedParams.delete(key);
          }
        });
      } else {
        // Update filters in the URL
        Object.entries(customerFilters).forEach(([key, value]) => {
          if (value) {
            updatedParams.set(key, String(value));
          } else {
            updatedParams.delete(key);
          }
        });
      }

      return updatedParams;
    });
  }, [customerFilters, customerPaginationModel, setSearchParams]);

  useEffect(() => {
    let fetchList: (() => void) | null = () => {
      setDataLoading(true);
      let PAYLOAD_DATA: GetAllCustomersByAgentProps["DATA"] = {
        searchKey: searchKey,
        agentId: ID,
        kycStatus: customerFilters?.kycStatus || undefined,
        serviceStatus: customerFilters?.serviceStatus || undefined,
        customerType: customerFilters?.customerType || undefined,
        identityType: customerFilters?.identityType || undefined,
        fromDate: customerFilters?.fromDate
          ? moment(customerFilters.fromDate).format("YYYY/MM/DD")
          : undefined,
        toDate: customerFilters?.toDate
          ? moment(customerFilters.toDate).format("YYYY/MM/DD")
          : undefined,
        pageNumber: customerPaginationModel.page + 1,
        pageSize: customerPaginationModel.pageSize,
      };

      let FILTERED_PAYLOAD_DATA = filterNonNullValues(PAYLOAD_DATA);

      GetCustomerByAgentId({
        DATA: FILTERED_PAYLOAD_DATA,
      })
        .then((res) => {
          const data = res?.data;

          if (data?.statusCode === AppStatusCode.api_success) {
            let count = res?.data?.meta?.count;
            if (count && count > 1) {
              if (count > 1) setTotalCustomerCount(count);
            } else setTotalCustomerCount(0);

            let DATA: any = res?.data?.data;
            DATA = DATA?.map((item: any) => ({
              id: item?._id,
              MSISDN: item?.MSISDN || "",
              nameAsDocument: item?.nameAsDocument || "",
              identityType: item?.identityType || "",
              kyc_Details: item?.kyc_Details,
              MSISDN_details: item?.MSISDN_details,
              createdAt: item?.createdAt || "",
            }));
            setData(DATA);
          } else {
            setData([]);
            setTotalCustomerCount(0);
          }
        })
        .catch(() => {
          setData([]);
          setTotalCustomerCount(0);
        })
        .finally(() => {
          setDataLoading(false);
        });
    };
    fetchList();
    return () => {
      fetchList = null;
    };
  }, [customerPaginationModel, refresh, customerFilters, searchKey]);

  useEffect(() => {
    let fetchAllAgentByDistributor: (() => void) | null = () => {
      setHandlersDataLoading(true);
      if (!ID) return;
      let PAYLOAD_DATA: GetAllHandlersByAgentProps["DATA"] = {
        searchKey: handlerSearchKey,
        agentId: ID,
        isActive:
          handlerFilters.activeStatus == "active"
            ? true
            : handlerFilters.activeStatus == "inActive"
            ? false
            : undefined,
        isApprove:
          handlerFilters.approveStatus == "approve"
            ? true
            : handlerFilters.approveStatus == "notApprove"
            ? false
            : undefined,
        pageNumber: handlerPaginationModel.page + 1,
        pageSize: handlerPaginationModel.pageSize,
      };

      let FILTERED_PAYLOAD_DATA = filterNonNullValues(PAYLOAD_DATA);
      GetAllHandlersByAgent({ DATA: FILTERED_PAYLOAD_DATA })
        .then((res) => {
          const data = res?.data;
          if (data?.statusCode === AppStatusCode.api_success) {
            let count = res?.data?.meta?.count;
            if (count && count > 1) {
              if (count > 1) setTotalHandlerCount(count);
            } else setTotalHandlerCount(0);
            let DATA: any = res?.data?.data;
            DATA = DATA?.map((item: any) => ({
              id: item?._id,
              name: item?.userDetails?.name || "",
              email: item?.userDetails?.email || "",
              phone: item?.userDetails?.phone || "",
              role: item?.userDetails?.role || "",
              date: item?.createdAt || "",
              isActive: item?.isActive,
            }));
            setHandlersData(DATA);
            setTotalHandlerCount(res?.data?.meta?.count);
          } else {
            setHandlersData([]);
            setTotalHandlerCount(0);
          }
        })
        .catch((err) => {
          setHandlersData([]);
          setTotalHandlerCount(0);
        })
        .finally(() => {
          setHandlersDataLoading(false);
          // setHandlersData(dummyData)
        });
    };
    fetchAllAgentByDistributor();
    return () => {
      fetchAllAgentByDistributor = null;
    };
  }, [
    handlerSearchKey,
    handlerPaginationModel,
    handlerFilters,
    handlerRefresh,
  ]);

  useEffect(() => {
    fetchAgentAnalytics();
    fetchAgentDetails();
    fetchCartons();
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <AgentAnalytics Data={analyticsData} />
      {agentDetails && (
        <AgentDetailsTable DATA={agentDetails} UserId={userId} />
      )}

      {/* //HANDLERS TABLE// */}
      <Card
        sx={{
          padding: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <Typography
          color="text.primary"
          className="tw-text-[18px]"
          sx={{ fontWeight: "bold" }}
        >
          {"Handlers List"}
        </Typography>
        <Grid container spacing={2} sx={{ marginBottom: "1rem" }}>
          <Grid item xs={12} sm={6} className="tw-flex tw-items-center">
            <Box sx={{ flex: "1 1 auto" }}>
              <SectionSearch
                name="search_sim"
                value={handlerSearchKey}
                onChange={handleHandlerSearch}
                onClear={handleClearHandlerSearch}
                onSubmit={handleHandlerSubmitSearch}
              />
            </Box>
            <HandlerDetailsFilter
              open={openHandlerFilter}
              onClose={toggleHandlerFilter}
              filters={handlerFilters}
              setFilters={setHandlerFilters}
              setOpen={setOpenHandlerFilter}
            />
          </Grid>
        </Grid>
        <HandlersTable
          DATA={handlersData}
          loading={handlersDataLoading}
          count={totalHandlercount}
          paginationModel={handlerPaginationModel}
          setPaginationModel={setHandlerPaginationModel}
        />
      </Card>

      {/* //CARTONS TABLE// */}
      <Card
        sx={{
          padding: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <Typography
          color="text.primary"
          className="tw-text-[18px]"
          sx={{ fontWeight: "bold" }}
        >
          {"Cartons List"}
        </Typography>
        <CartonsTable DATA={cartons} loading={cartonLoading} />
      </Card>

      {/* //CUSTOMERS TABLE// */}
      <Card
        sx={{
          padding: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <Typography
          color="text.primary"
          className="tw-text-[18px]"
          sx={{ fontWeight: "bold" }}
        >
          {"Customers List"}
        </Typography>
        <Grid container spacing={2} sx={{ marginBottom: "1rem" }}>
          <Grid item xs={12} sm={6} className="tw-flex tw-items-center">
            <Box sx={{ flex: "1 1 auto" }}>
              <SectionSearch
                name="search_sim"
                value={searchKey}
                onChange={handleSearch}
                onClear={handleClearSearch}
                onSubmit={handleSubmitSearch}
              />
            </Box>

            <CustomerManagementFilter
              open={openFilter}
              onClose={toggleFilter}
              filters={customerFilters}
              setFilters={setCustomerFilters}
              setOpen={setOpenFilter}
            />
          </Grid>
        </Grid>
        <CustomerManagementTable
          data={data}
          loading={dataLoading}
          count={totalCustomercount}
          paginationModel={customerPaginationModel}
          setPaginationModel={setCustomerPaginationModel}
        />
      </Card>
    </Box>
  );
};

export default AgentDeailsLayout;
