import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { FormInputProps } from "_interfaces/_common/forms";
import { SideDrawerProps } from "_interfaces/_common/sideDrawer";
import { PackageBundleFilterModel } from "_models/data/package-bundle/data.package-bundle.model";
import InputAutoComplete from "component/_common/forms/inputAutoComplete";
import SideDrawer from "parts/sideDrawer";
import React, { Fragment, useMemo } from "react";
import { generateAutocompleteOptions } from "functions/helper";
import {
  ALLOWED_PACKAGE_BUNDLE_FIELDS_category,
  ALLOWED_PACKAGE_BUNDLE_FIELDS_packageFor,
  ALLOWED_PACKAGE_BUNDLE_FIELDS_packageType,
} from "_interfaces/functions/http-requests/package-bundle";
import { CustomFilter } from "parts/customFilter";

interface InputListProps extends FormInputProps {
  name:
    | "isActive"
    | "category"
    | "packageType"
    | "packageFor"
    | "packageValidity";
}
interface Props extends SideDrawerProps {
  filters: PackageBundleFilterModel;
  setFilters: React.Dispatch<React.SetStateAction<PackageBundleFilterModel>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const PackageBundleFilter: React.FC<Props> = ({
  open,
  setOpen,
  filters,
  setFilters,
}) => {
  const inputList: InputListProps[] = useMemo(
    () => [
      {
        type: "autoComplete",
        name: "isActive",
        label: "Filter Status",
        placeholder: "Select Status",
        options: [
          { id: "true", value: "true", title: "Active", isActive: true },
          { id: "false", value: "false", title: "Inactive", isActive: true },
        ],
        grid: 12,
        multiple: false,
      },
      {
        type: "autoComplete",
        name: "category",
        label: "Category",
        placeholder: "Select Category",
        options: generateAutocompleteOptions(
          ALLOWED_PACKAGE_BUNDLE_FIELDS_category
        ),
        multiple: false,
        grid: 12,
      },
      {
        type: "autoComplete",
        name: "packageType",
        label: "Package Type",
        placeholder: "Select Package Type",
        options: generateAutocompleteOptions(
          ALLOWED_PACKAGE_BUNDLE_FIELDS_packageType
        ),
        multiple: false,
        grid: 12,
      },
      {
        type: "autoComplete",
        name: "packageFor",
        label: "Package For",
        placeholder: "Select Package For",
        options: generateAutocompleteOptions(
          ALLOWED_PACKAGE_BUNDLE_FIELDS_packageFor
        ),
        multiple: false,
        grid: 12,
      },
      {
        type: "autoComplete",
        name: "packageValidity",
        label: "Package Validity",
        placeholder: "Select Validity",
        options: [
          {
            id: "7",
            value: "7",
            title: "Weekly",
            isActive: true,
          },
          {
            id: "30",
            value: "30",
            title: "Monthly",
            isActive: true,
          },
          {
            id: "365",
            value: "365",
            title: "Yearly",
            isActive: true,
          },
        ],
        grid: 12,
        multiple: false,
      },
    ],
    []
  );

  return (
    <>
      <CustomFilter
        filters={filters}
        setFilters={setFilters}
        filterList={inputList}
        open={open as boolean}
        setOpen={setOpen}
      />
    </>
  );
};

export default PackageBundleFilter;
