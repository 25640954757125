interface Props {
  moduleName?: 'agent' | 'handler' | 'customer' | 'regional_distributer' | 'chanel' | '';
  level?: "error" | "warning" | "normal" | "";
  alertLevel?: "veryHigh" | "high" | "medium" | "normal" | "";
  fromDate?: Date | null; // YYYY-MM-DD
  toDate?: Date | null; // YYYY-MM-DD
  activity: "" | 'upload_sim' | 'upload_msisdn' | 'create-agent' | 'approve-agent' |
  'update-agent' | 'create-handler' | 'update-handler' | 'handler-activation' |
  'sim_carton_uploaded' | 'payment_momo' | 'registration_final_submit' |
  'DE_REGISTRATION' | 'SIM_SWAP' | 'msisdn_carton_upload' | 'create_wallet' |
  'credit_wallet' | 'debit_wallet' | 'hlr_hss_resubmit' |
  'registration_resubmit' | 'cbs_resubmit' | 'wallet_pin_change' |
  'wallet_recharge' | 'wallet_transaction' | 'block_user' | "",
  actionToServer: "" | "tag" | "inventory" | "customer_api_service";
  actionFromServer: "" | "tag" | "inventory" | "customer_api_service";
}

const defaultProps: Props = {
  moduleName: "",
  level: "",
  alertLevel: "",
  fromDate: null,
  toDate: null,
  activity: "",
  actionToServer: "",
  actionFromServer: "",
};

export class GlobalActivityLogFilterModel {
  moduleName?: 'agent' | 'handler' | 'customer' | 'regional_distributer' | 'chanel' | "";
  level?: "error" | "warning" | "normal" | "";
  alertLevel?: "veryHigh" | "high" | "medium" | "normal" | "";
  fromDate?: Date | null; // YYYY-MM-DD
  toDate?: Date | null; // YYYY-MM-DD
  activity?: "" | 'upload_sim' | 'upload_msisdn' | 'create-agent' | 'approve-agent' |
    'update-agent' | 'create-handler' | 'update-handler' | 'handler-activation' |
    'sim_carton_uploaded' | 'payment_momo' | 'registration_final_submit' |
    'DE_REGISTRATION' | 'SIM_SWAP' | 'msisdn_carton_upload' | 'create_wallet' |
    'credit_wallet' | 'debit_wallet' | 'hlr_hss_resubmit' |
    'registration_resubmit' | 'cbs_resubmit' | 'wallet_pin_change' |
    'wallet_recharge' | 'wallet_transaction' | 'block_user' | "";
  actionToServer?: "" | "tag" | "inventory" | "customer_api_service";
  actionFromServer?: "" | "tag" | "inventory" | "customer_api_service";

  constructor(data: Props = defaultProps) {
    this.moduleName = data.moduleName || "";
    this.level = data.level || "";
    this.alertLevel = data.alertLevel || "";
    this.fromDate = data.fromDate || null;
    this.toDate = data.toDate || null;
    this.activity = data.activity || "";
    this.actionFromServer = data.actionFromServer || "";
    this.actionToServer = data.actionToServer || "";
  }
}
