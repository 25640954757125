import { generateAutocompleteOptions } from "functions/helper";
import React, { useMemo } from "react";
import { FormInputProps } from "_interfaces/_common/forms";
import { SideDrawerProps } from "_interfaces/_common/sideDrawer";
import { CustomFilter } from "parts/customFilter";
import { TransactionFilterModel } from "_models/data/transactions/data.transaction-filter.model";

interface InputListProps extends FormInputProps {
  name: 'paymentMethod' | 'paymentBy' | 'paymentType' | 'dateRange'
}
interface Props extends SideDrawerProps {
  filters: TransactionFilterModel;
  setFilters: React.Dispatch<
    React.SetStateAction<TransactionFilterModel>
  >;
  setOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

const TransactionFilter: React.FC<Props> = ({
  open,
  filters,
  setFilters,
  setOpen
}) => {

  const inputList: InputListProps[] = useMemo(
    () => [
      {
        type: "autoComplete",
        name: "paymentBy",
        label: "Filter By Payment",
        placeholder: "Select Payment",
        options: generateAutocompleteOptions([
          'momo', 'airtelMoney', 'interSwitch', 'wallet'
        ]),
        multiple: false,
      },
      {
        type: "autoComplete",
        name: "paymentType",
        label: "Filter by Payment Type",
        placeholder: "Select Payment Type",
        options: generateAutocompleteOptions(['credit', 'debit', 'refund']),
        multiple: false,
      },
      {
        type: "autoComplete",
        name: "paymentMethod",
        label: "Filter by Payment Method",
        placeholder: "Select Payment Method",
        options: generateAutocompleteOptions(['mobileMoney', 'creditCard']),
        multiple: false,
      },
      {
        type: "dateRange",
        name: "dateRange",
        label: "Select Date range",
        placeholder: "Select Date range",
      },
    ],
    []
  );

  return (
    <>
      <CustomFilter
        filters={filters}
        setFilters={setFilters}
        filterList={inputList}
        open={open as boolean}
        setOpen={setOpen}
      />
    </>
  );
};

export default TransactionFilter;
