import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CustomDateRangePicker from "component/_common/forms/customDateRangePicker";
import InputAutoComplete from "component/_common/forms/inputAutoComplete";
import { generateAutocompleteOptions } from "functions/helper";
import moment, { Moment } from "moment";
import SideDrawer from "parts/sideDrawer";
import React, { Fragment, useMemo } from "react";
import { FormInputProps } from "_interfaces/_common/forms";
import { SideDrawerProps } from "_interfaces/_common/sideDrawer";
import { CustomerManagementFilterModel } from "_models/data/customer-management/data.customer-management-filter.model";
import { CustomFilter } from "parts/customFilter";

interface InputListProps extends FormInputProps {
  name:
    | "kycStatus"
    | "serviceStatus"
    | "customerType"
    | "identityType"
    | "dateRange";
}
interface Props extends SideDrawerProps {
  filters: CustomerManagementFilterModel;
  setFilters: React.Dispatch<
    React.SetStateAction<CustomerManagementFilterModel>
  >;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomerManagementFilter: React.FC<Props> = ({
  open,
  setOpen,
  filters,
  setFilters,
}) => {
  const inputList: InputListProps[] = useMemo(
    () => [
      {
        type: "autoComplete",
        name: "kycStatus",
        label: "Filter by KYC status",
        placeholder: "Select KYC status",
        options: generateAutocompleteOptions([
          "pending",
          "rejected",
          "success",
        ]),
        multiple: false,
      },
      {
        type: "autoComplete",
        name: "serviceStatus",
        label: "Filter by Service status",
        placeholder: "Select Service status",
        options: generateAutocompleteOptions([
          "inprogress",
          "active",
          "notActive",
          "rejected",
          "blocked",
        ]),
        multiple: false,
      },
      {
        type: "autoComplete",
        name: "customerType",
        label: "Filter by Customer type",
        placeholder: "Select Customer type",
        options: generateAutocompleteOptions(["individual", "company"]),
        multiple: false,
      },
      {
        type: "autoComplete",
        name: "identityType",
        label: "Filter by Identity type",
        placeholder: "Select Identity type",
        options: generateAutocompleteOptions(["citizen", "tourist", "refugee"]),
        multiple: false,
      },
      {
        type: "dateRange",
        name: "dateRange",
        label: "Select Date range",
        placeholder: "Select Date range",
      },
    ],
    []
  );

  return (
    <>
      <CustomFilter
        filters={filters}
        setFilters={setFilters}
        filterList={inputList}
        open={open as boolean}
        setOpen={setOpen}
      />
    </>
  );
};

export default CustomerManagementFilter;
