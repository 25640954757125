import { GridPaginationModel } from "@mui/x-data-grid";
import AgentsTableInterface from "_interfaces/agent";
import { AgentGetAllProps } from "_interfaces/functions/http-requests/agents";
import { AgentsFilterModel } from "_models/data/agent/data.agents-filter.model";
import { AppStatusCode } from "config/appStatusCode";
import { filterNonNullValues } from "functions/helper";
import { GetAllAgent } from "functions/http-requests/agents";
import { useEffect, useRef, useState } from "react";
import AgentsTable from "./agentsTable";
import { UpdateRegionalDistributorProps } from "_interfaces/functions/http-requests/distributor-channels/regional-distributors";
import { Grid, Box } from "@mui/material";
import SectionSearch from "parts/sectionSearch";
import CustomDrawer from "parts/customDialog/customDrawer";
import AgentsForm from "./agentsForm";
import AgentsFilter from "./agnetsFilter";
import { useSearchParams } from "react-router-dom";

const AgentsLayout = () => {
  const isInitialRender = useRef(true);
  const [data, setData] = useState<AgentsTableInterface["data"]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchKey, setSearchKey] = useState<string>(
    searchParams.get("search") || ""
  );
  const [count, setCount] = useState<number>(0);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: Number(searchParams.get("page")) || 0,
    pageSize: Number(searchParams.get("pageSize")) || 10,
  });
  const [filters, setFilters] = useState(() => {
    const initialFilters: AgentsFilterModel = new AgentsFilterModel();
    for (const [key, value] of searchParams.entries()) {
      if (key !== "page" && key !== "pageSize" && key !== "search") {
        (initialFilters as Record<string, string | number | null>)[key] =
          value || "";
      }
    }
    return initialFilters;
  });
  const [refresh, setRefresh] = useState<boolean>(false);
  const [editId, setEditId] =
    useState<UpdateRegionalDistributorProps["ID"]>("");
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  const toggleForm = () => {
    if (editId) return setEditId("");
    setOpenForm(!openForm);
  };

  const toggleFilter = () => {
    setOpenFilter(!openFilter);
  };

  const handleRefresh = () => setRefresh(!refresh);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchKey = e.target.value;
    setSearchKey(newSearchKey);
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);
      updatedParams.set("search", newSearchKey);
      return updatedParams;
    });
  };

  const handleSubmitSearch = () => {
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);
      updatedParams.set("search", searchKey);
      updatedParams.set("page", "0"); // Reset to the first page on search submit
      updatedParams.set("pageSize", `${paginationModel.pageSize}`);
      return updatedParams;
    });
    isInitialRender.current = false;
    handleRefresh();
  };

  const handleClearSearch = () => {
    setSearchKey("");
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);
      // Remove the 'searchKey' parameter from the URL if it exists
      updatedParams.delete("search");
      return updatedParams;
    });
  };

  useEffect(() => {
    const search = searchParams.get("search") || "";
    setSearchKey(search);
  }, [searchParams]);

  useEffect(() => {
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);

      updatedParams.set("page", `${paginationModel.page}`);
      updatedParams.set("pageSize", `${paginationModel.pageSize}`);

      // If the filters object is empty, clear all filter parameters from the URL
      if (Object.keys(filters).length === 0) {
        // Remove all filter-related keys from the URL
        Array.from(updatedParams.keys()).forEach((key) => {
          if (key !== "search" && key !== "page" && key !== "pageSize") {
            updatedParams.delete(key);
          }
        });
      } else {
        // Update filters in the URL
        Object.entries(filters).forEach(([key, value]) => {
          if (value) {
            updatedParams.set(key, String(value));
          } else {
            updatedParams.delete(key);
          }
        });
      }

      return updatedParams;
    });
  }, [filters, paginationModel, setSearchParams]);

  useEffect(() => {
    let fetchList: (() => void) | null = () => {
      setDataLoading(true);

      let PAYLOAD_DATA: AgentGetAllProps["DATA"] = {
        searchKey: searchKey,
        pageNumber: paginationModel.page + 1,
        pageSize: paginationModel.pageSize,
        isActive:
          filters?.activeStatus === "active"
            ? true
            : filters?.activeStatus === "inActive"
            ? false
            : undefined,
        isApprove:
          filters?.approveStatus === "approve"
            ? true
            : filters?.approveStatus === "notApprove"
            ? false
            : undefined,
        type: filters?.type || undefined,
      };

      PAYLOAD_DATA = filterNonNullValues(PAYLOAD_DATA);

      GetAllAgent({ DATA: PAYLOAD_DATA })
        .then((res) => {
          const data = res?.data;
          if (data?.statusCode === AppStatusCode.api_success) {
            let count = res?.data?.meta?.count;
            if (count && count > 1) {
              if (count > 1) setCount(count);
            } else setCount(0);

            let DATA: any = res?.data?.data;
            DATA = DATA?.map((item: any) => ({
              id: item?._id,
              name: item?.name || item?.userDetails?.name,
              email: item?.email || item?.userDetails?.email,
              phone: item?.phone || item?.userDetails?.phone,
              assignedLocation: item?.assignedLocation || [],
              isActive: item?.isActive,
              date: item?.createdAt,
            }));
            setData(DATA);
          } else {
            setData([]);
            setCount(0);
          }
        })
        .catch(() => {
          setData([]);
          setCount(0);
        })
        .finally(() => {
          setDataLoading(false);
        });
    };
    fetchList();
    return () => {
      fetchList = null;
    };
  }, [refresh, paginationModel, filters, searchKey]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} className="tw-flex tw-items-center">
          <Box sx={{ flex: "1 1 auto" }}>
            <SectionSearch
              name="search_regional_distributor"
              value={searchKey}
              onChange={handleSearch}
              onClear={handleClearSearch}
              onSubmit={handleSubmitSearch}
            />
          </Box>
          <AgentsFilter
            open={openFilter}
            onClose={toggleFilter}
            filters={filters}
            setFilters={setFilters}
            setOpen={setOpenFilter}
          />
        </Grid>
        {/* <Grid item xs={12} sm={6}>
                    <Box className="tw-flex tw-justify-end tw-items-center">
                        <Button
                            onClick={toggleForm}
                            variant="outlined"
                            startIcon={<AddIcon />}
                            disableElevation
                        >
                            <Box component="span" className="tw-line-clamp-1">
                                Add New Agent
                            </Box>
                        </Button>
                    </Box>
                </Grid> */}
      </Grid>

      <AgentsTable
        data={data}
        loading={dataLoading}
        count={count}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        setEditId={setEditId}
        setFilters={setFilters}
      />

      <CustomDrawer
        open={openForm || editId ? true : false}
        onClose={toggleForm}
        title={editId ? "Edit Distributor Details" : "Add New Distributor"}
      >
        <AgentsForm
          handleRefresh={handleRefresh}
          onClose={toggleForm}
          editId={editId}
        />
      </CustomDrawer>
    </>
  );
};

export default AgentsLayout;
