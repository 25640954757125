import { generateAutocompleteOptions } from "functions/helper";
import React, { useMemo } from "react";
import { SideDrawerProps } from "_interfaces/_common/sideDrawer";
import { HandlersFilterModel } from "_models/data/handler/data.handlers-filter.model";
import { CustomFilter } from "parts/customFilter";

interface Props extends SideDrawerProps {
  filters: HandlersFilterModel;
  setFilters: React.Dispatch<React.SetStateAction<HandlersFilterModel>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const HandlerDetailsFilter: React.FC<Props> = ({
  open,
  setOpen,
  filters,
  setFilters,
}) => {
  const inputList: any[] = useMemo(
    () => [
      {
        type: "autoComplete",
        name: "activeStatus",
        label: "Filter by Active status",
        placeholder: "Select Active status",
        options: generateAutocompleteOptions(["inActive", "active"]),
        multiple: false,
      },
      {
        type: "autoComplete",
        name: "approveStatus",
        label: "Filter by Approve status",
        placeholder: "Select Approve status",
        options: generateAutocompleteOptions(["approve", "notApprove"]),
        multiple: false,
      },
    ],
    []
  );

  return (
    <>
      <CustomFilter
        filters={filters}
        setFilters={setFilters}
        filterList={inputList}
        open={open as boolean}
        setOpen={setOpen}
      />
    </>
  );
};

export default HandlerDetailsFilter;
