import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { FormInputProps } from "_interfaces/_common/forms";
import { SideDrawerProps } from "_interfaces/_common/sideDrawer";
import { ALLOWED_INFLUENCER_CATEGORY } from "_interfaces/functions/http-requests/influencer";
import { InfluencerFilterModel } from "_models/data/influencer/data.influencer-filter.model";
import InputAutoComplete from "component/_common/forms/inputAutoComplete";
import { generateAutocompleteOptions } from "functions/helper";
import { CustomFilter } from "parts/customFilter";
import SideDrawer from "parts/sideDrawer";
import React, { Fragment, useMemo } from "react";

interface InputListProps extends FormInputProps {
  name: "category";
}
interface Props extends SideDrawerProps {
  filters: InfluencerFilterModel;
  setFilters: React.Dispatch<React.SetStateAction<InfluencerFilterModel>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SimManagementFilter: React.FC<Props> = ({
  open,
  setOpen,
  filters,
  setFilters,
}) => {
  const inputList: InputListProps[] = useMemo(
    () => [
      {
        type: "autoComplete",
        name: "category",
        label: "Filter by Category",
        placeholder: "Select Category",
        options: generateAutocompleteOptions(ALLOWED_INFLUENCER_CATEGORY),
        multiple: false,
      },
    ],
    [ALLOWED_INFLUENCER_CATEGORY]
  );

  return (
    <>
      <CustomFilter
        filters={filters}
        setFilters={setFilters}
        filterList={inputList}
        open={open as boolean}
        setOpen={setOpen}
      />
    </>
  );
};

export default SimManagementFilter;
