import { Grid } from "@mui/material";
import { GridPaginationModel } from "@mui/x-data-grid";
import {
  GetThirdPartyApiAlertsProps,
  ThirtPartyApiAlertsTable,
} from "_interfaces/log_alerts";
import { AppStatusCode } from "config/appStatusCode";
import { GetThirdPartyApiAlerts } from "functions/http-requests/log_alerts";
import { useEffect, useRef, useState } from "react";
import ThirdPartyLogAlertsTable from "./thirdPartyLogAlertsTable";
import ThirdPartyLogAlertsFilter from "./third_party_log_alerts_filter";
import { ThirdPartyLogFilterModel } from "_models/data/third_party_api_alerts/data.third-party-api-filter.model";
import moment from "moment";
import { useSearchParams } from "react-router-dom";

const LogAlertsLayout = () => {
  const isInitialRender = useRef(true);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [data, setData] = useState<ThirtPartyApiAlertsTable[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchKey, setSearchKey] = useState<string>("");
  const [thirdPartyFilters, setThirdPartyFilters] = useState(() => {
    const initialFilters: ThirdPartyLogFilterModel =
      new ThirdPartyLogFilterModel();
    for (const [key, value] of searchParams.entries()) {
      if (key !== "page" && key !== "pageSize" && key !== "search") {
        (initialFilters as Record<string, string | number | null>)[key] =
          value || "";
      }
    }
    return initialFilters;
  });
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: Number(searchParams.get("page")) || 0,
    pageSize: Number(searchParams.get("pageSize")) || 10,
  });

  const toggleFilter = () => {
    setOpenFilter(!openFilter);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchKey(value);
  };
  const [refresh, setRefresh] = useState<boolean>(false);
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  const handleClearSearch = () => setSearchKey("");

  const handleSubmitSearch = () => {
    isInitialRender.current = false;
    setPaginationModel((prev) => ({ ...prev, page: 0 }));
    handleRefresh();
  };

  const handleRefresh = () => setRefresh(!refresh);

  useEffect(() => {
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);

      updatedParams.set("page", `${paginationModel.page}`);
      updatedParams.set("pageSize", `${paginationModel.pageSize}`);

      // If the filters object is empty, clear all filter parameters from the URL
      if (Object.keys(thirdPartyFilters).length === 0) {
        // Remove all filter-related keys from the URL
        Array.from(updatedParams.keys()).forEach((key) => {
          if (key !== "page" && key !== "pageSize") {
            updatedParams.delete(key);
          }
        });
      } else {
        // Update filters in the URL
        Object.entries(thirdPartyFilters).forEach(([key, value]) => {
          if (value) {
            updatedParams.set(key, String(value));
          } else {
            updatedParams.delete(key);
          }
        });
      }

      return updatedParams;
    });
  }, [thirdPartyFilters, paginationModel, setSearchParams]);

  useEffect(() => {
    let fetchList: (() => void) | null = () => {
      setDataLoading(true);
      let PAYLOAD_DATA: GetThirdPartyApiAlertsProps["DATA"] = {
        pageNumber: (paginationModel.page + 1).toString(),
        pageSize: paginationModel.pageSize.toString(),
        apiModule: thirdPartyFilters.apiModule || "",
        level: thirdPartyFilters.level || "",
        alertLevel: thirdPartyFilters.alertLevel || "",
        fromDate: thirdPartyFilters?.fromDate
          ? moment(thirdPartyFilters.fromDate).format("YYYY/MM/DD")
          : "",
        toDate: thirdPartyFilters?.toDate
          ? moment(thirdPartyFilters.toDate).format("YYYY/MM/DD")
          : "",
      };

      GetThirdPartyApiAlerts({
        DATA: PAYLOAD_DATA,
      })
        .then((res) => {
          const data = res?.data;
          if (data?.statusCode === AppStatusCode.api_success) {
            let count = res?.data?.meta?.count[0]?.count;
            if (count && count > 1) {
              if (count > 1) setCount(count);
            } else setCount(0);

            let DATA: any = res?.data?.data;
            DATA = DATA?.map((item: any) => ({
              id: item?._id,
              activity: item?.activity || "",
              moduleName: item?.apiModule || "",
              message: item?.message || "",
              activityId: item?.activityId || "",
              time: item?.time,
              level: item?.level,
              alertLevel: item?.alertLevel,
              createdAt: item?.createdAt,
            }));
            setData(DATA);
          } else {
            setData([]);
            setCount(0);
          }
        })
        .catch(() => {
          setData([]);
          setCount(0);
        })
        .finally(() => {
          setDataLoading(false);
        });
    };
    fetchList();
    return () => {
      fetchList = null;
    };
  }, [paginationModel, thirdPartyFilters, refresh]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} className="tw-flex tw-items-center">
          {/* <Box sx={{ flex: "1 1 auto" }}>
                        <SectionSearch
                            name="search_regional_distributor"
                            value={searchKey}
                            onChange={handleSearch}
                            onClear={handleClearSearch}
                            onSubmit={handleSubmitSearch}
                        />
                    </Box> */}
          <ThirdPartyLogAlertsFilter
            open={openFilter}
            onClose={toggleFilter}
            filters={thirdPartyFilters}
            setFilters={setThirdPartyFilters}
            setOpen={setOpenFilter}
          />
        </Grid>
      </Grid>

      <ThirdPartyLogAlertsTable
        data={data}
        loading={dataLoading}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        count={count}
      />
    </>
  );
};

export default LogAlertsLayout;
