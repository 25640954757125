import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CustomDateRangePicker from "component/_common/forms/customDateRangePicker";
import InputAutoComplete from "component/_common/forms/inputAutoComplete";
import { generateAutocompleteOptions } from "functions/helper";
import moment from "moment";
import SideDrawer from "parts/sideDrawer";
import React, { Fragment, useMemo } from "react";
import { FormInputProps } from "_interfaces/_common/forms";
import { SideDrawerProps } from "_interfaces/_common/sideDrawer";
import { ThirdPartyLogFilterModel } from "_models/data/third_party_api_alerts/data.third-party-api-filter.model";
import { CustomFilter } from "parts/customFilter";

interface InputListProps extends FormInputProps {
  name: "apiModule" | "level" | "alertLevel" | "dateRange";
}
interface Props extends SideDrawerProps {
  filters: ThirdPartyLogFilterModel;
  setFilters: React.Dispatch<React.SetStateAction<ThirdPartyLogFilterModel>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ThirdPartyLogAlertsFilter: React.FC<Props> = ({
  open,
  setOpen,
  filters,
  setFilters,
}) => {
  const inputList: InputListProps[] = useMemo(
    () => [
      {
        type: "autoComplete",
        name: "apiModule",
        label: "Filter by Api Module",
        placeholder: "Select Api Module",
        options: generateAutocompleteOptions([
          "cbs",
          "tag",
          "hlr",
          "hss",
          "nira",
          "ucc",
          "service",
          "sms",
          "momo",
          "esim",
        ]),
        multiple: false,
      },
      {
        type: "autoComplete",
        name: "level",
        label: "Filter by Level",
        placeholder: "Select Level",
        options: generateAutocompleteOptions(["error", "warning", "normal"]),
        multiple: false,
      },
      {
        type: "autoComplete",
        name: "alertLevel",
        label: "Filter by Alert Level",
        placeholder: "Select Alert Level",
        options: generateAutocompleteOptions([
          "veryHigh",
          "high",
          "medium",
          "normal",
        ]),
        multiple: false,
      },
      {
        type: "dateRange",
        name: "dateRange",
        label: "Select Date range",
        placeholder: "Select Date range",
      },
    ],
    []
  );

  return (
    <>
      <CustomFilter
        filters={filters}
        setFilters={setFilters}
        filterList={inputList}
        open={open as boolean}
        setOpen={setOpen}
      />
    </>
  );
};

export default ThirdPartyLogAlertsFilter;
