import {
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { ServerGroupListInterface } from "_interfaces/server";
import EditIcon from "@mui/icons-material/Edit";
import { useState } from "react";
import { UpdateServerProps } from "_interfaces/functions/http-requests/server";
import { setAlert } from "state/reducers/alert";
import { useDispatch } from "react-redux";
import { Preview } from "@mui/icons-material";
import { AppStatusCode } from "config/appStatusCode";
import { updateServer } from "functions/http-requests/server";
import { toggleLoading } from "state/reducers/loading";
import StatusDialog from "parts/customDialog/statusDialog";

interface Props {
  data: ServerGroupListInterface[];
  handleDefaultServer: (serverName: string, type: string) => void;
  getAllServerList: () => void;
}

interface EditingInterface {
  open: boolean;
  serverName: string;
  id: string;
  url: string;
  type: string;
}
interface DefaultServerDataInterface {
  open: boolean;
  serverName: string;
  type: string;
}

export const ServerList: React.FC<Props> = ({
  data,
  handleDefaultServer,
  getAllServerList,
}) => {
  const emptyEdit = {
    open: false,
    serverName: "",
    id: "",
    url: "",
    type: "",
  };
  const Dispatch = useDispatch();
  const [editing, setEditing] = useState<EditingInterface>(emptyEdit);
  const [defaultServerData, setDefaultServerData] =
    useState<DefaultServerDataInterface>({
      open: false,
      serverName: "",
      type: "",
    });

  const handleEditClick = (props: EditingInterface) => {
    setEditing(props);
  };

  const handleUpdate = (props: UpdateServerProps["DATA"]) => {
    if (!props.url) {
      Dispatch(
        setAlert({
          type: "warning",
          message: "Please enter the url for update!",
        })
      );
      return;
    }
    if (!props.serverName || !props.type || !props.url) {
      Dispatch(
        setAlert({
          type: "warning",
          message: "Please provide required fields!",
        })
      );
      return;
    }
    try {
      Dispatch(toggleLoading(true));
      props.url = props.url.replace(/\s+/g, "");
      updateServer({ DATA: props }).then((res) => {
        const result = res?.data;
        if (
          result?.statusCode === AppStatusCode.api_success ||
          result?.statusCode === AppStatusCode.api_updated
        ) {
          Dispatch(
            setAlert({
              type: "success",
              message: result?.message,
            })
          );
          getAllServerList();
          setEditing(emptyEdit);
        } else {
          Dispatch(
            setAlert({
              type: "error",
              message: "There is some error to update the url! ",
            })
          );
        }
      });
    } catch (error) {
      Dispatch(
        setAlert({
          type: "error",
          message: "There is some error to update the url! ",
        })
      );
    } finally {
      Dispatch(toggleLoading(true));
    }
  };

  const onClose = () => {
    setEditing(emptyEdit);
  };

  return (
    <>
      <Box sx={{ padding: 2, marginBottom: 2 }}>
        {data?.map((val, index) => (
          <Box
            className="tw-border"
            key={index}
            sx={{
              padding: 2,
              marginBottom: 2,
              display: "flex",
              gap: "5rem",
              alignItems: "center",
              borderRadius: "7.7px",
            }}
          >
            <Typography
              variant="h6"
              sx={{ marginBottom: 1, fontSize: "23px", width: "8rem" }}
            >
              {val.serverName}
            </Typography>
            <Box className="tw-flex tw-gap-3 tw-flex-wrap tw-flex-end">
              {val.serverDetails.map((detailsVal, detailsIndex) => (
                <Card
                  className="tw-flex tw-flex-col tw-gap-3 w-96"
                  key={detailsIndex}
                  sx={{
                    padding: 2,
                    marginBottom: 1,
                    backgroundColor: "#ffffff",
                    width: "25rem",
                  }}
                >
                  <Box className="tw-flex tw-gap-3 tw-items-center">
                    <Typography>Type:</Typography>
                    <Typography className="tw-capitalize">
                      {detailsVal.type}
                    </Typography>
                  </Box>
                  <Box className="tw-flex tw-gap-3 tw-items-center">
                    <Typography>Is Default: </Typography>
                    <Typography
                      sx={{
                        color: "#00ba00",
                        fontWeight: 500,
                        fontSize: "17px",
                      }}
                    >
                      {detailsVal.isDefault ? (
                        "Yes"
                      ) : (
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() =>
                            setDefaultServerData({
                              open: true,
                              serverName: val.serverName,
                              type: detailsVal.type,
                            })
                          }
                        >
                          Make Default
                        </Button>
                      )}
                    </Typography>
                  </Box>
                  <Box className="tw-flex tw-gap-5 tw-items-center">
                    <Typography>URL: </Typography>
                    <Box
                      className="tw-flex tw-gap-2"
                      sx={{ cursor: "pointer", alignItems: "center" }}
                      onClick={() =>
                        handleEditClick({
                          open: true,
                          serverName: val.serverName,
                          id: detailsVal.id,
                          url: detailsVal.url,
                          type: detailsVal.type,
                        })
                      }
                    >
                      <Typography>{detailsVal?.url}</Typography>
                      <EditIcon sx={{ width: "20px" }} />
                    </Box>
                  </Box>
                </Card>
              ))}
            </Box>
          </Box>
        ))}
      </Box>
      <Dialog
        open={editing.open}
        onClose={onClose}
        fullWidth
        maxWidth={"xs"}
        sx={{ minWidth: 220 }}
      >
        <DialogTitle sx={{ fontSize: 24 }}>Update URL</DialogTitle>
        <DialogContent>
          <Box pt={3}>
            <TextField
              sx={{ width: "17rem" }}
              id="url"
              placeholder="URL"
              variant="outlined"
              value={editing.url}
              error={!editing.url} // Show error when URL is empty
              helperText={!editing.url && "URL is required"}
              onChange={(e) =>
                setEditing((prev) => ({
                  ...prev,
                  url: e.target.value,
                }))
              }
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              handleUpdate({
                type: editing.type,
                url: editing.url,
                serverName: editing.serverName,
              })
            }
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
      <StatusDialog
        open={defaultServerData.open}
        successButtonName="Yes"
        onClose={() => {
          setDefaultServerData((prev) => ({
            ...prev,
            open: false,
          }));
        }}
        onChangeStatus={() =>
          handleDefaultServer(
            defaultServerData.serverName,
            defaultServerData.type
          )
        }
        title="Make Default"
        content="Are you sure you want to make this default?"
      />
    </>
  );
};
