import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { GridPaginationModel } from "@mui/x-data-grid";
import { GetInventoryUsersProps } from "_interfaces/functions/http-requests/inventory-users";
import InventoryUsersTableInterface from "_interfaces/inventory-users/inventoryUsersTable";
import { InventoryUsersFilterModel } from "_models/data/inventory-users/data.inventory-users-filter.model";
import { AppStatusCode } from "config/appStatusCode";
import { GetInventoryUsers } from "functions/http-requests/inventory-users";
import CustomDrawer from "parts/customDialog/customDrawer";
import { useEffect, useState } from "react";
import InventoryUsersFilter from "./inventoryUsersFilter";
import InventoryUsersForm from "./inventoryUsersForm";
import InventoryUsersTable from "./inventoryUsersTable";
import { useSearchParams } from "react-router-dom";

const InventoryUsersLayout = () => {
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState<InventoryUsersTableInterface["data"]>([]);

  const [openForm, setOpenForm] = useState<boolean>(false);

  const [refresh, setRefresh] = useState<boolean>(false);

  const [count, setCount] = useState<number>(0);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: Number(searchParams.get("page")) || 0,
    pageSize: Number(searchParams.get("pageSize")) || 10,
  });

  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [filters, setFilters] = useState(() => {
    const initialFilters: InventoryUsersFilterModel =
      new InventoryUsersFilterModel();
    for (const [key, value] of searchParams.entries()) {
      if (key !== "page" && key !== "pageSize" && key !== "search") {
        (initialFilters as Record<string, string | number | null>)[key] =
          value || "";
      }
    }
    return initialFilters;
  });

  const toggleForm = () => {
    setOpenForm(!openForm);
  };

  const toggleFilter = () => {
    setOpenFilter(!openFilter);
  };

  const handleRefresh = () => setRefresh(!refresh);

  useEffect(() => {
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);

      updatedParams.set("page", `${paginationModel.page}`);
      updatedParams.set("pageSize", `${paginationModel.pageSize}`);

      // If the filters object is empty, clear all filter parameters from the URL
      if (Object.keys(filters).length === 0) {
        // Remove all filter-related keys from the URL
        Array.from(updatedParams.keys()).forEach((key) => {
          if (key !== "page" && key !== "pageSize") {
            updatedParams.delete(key);
          }
        });
      } else {
        // Update filters in the URL
        Object.entries(filters).forEach(([key, value]) => {
          if (value) {
            updatedParams.set(key, String(value));
          } else {
            updatedParams.delete(key);
          }
        });
      }

      return updatedParams;
    });
  }, [filters, paginationModel, setSearchParams]);

  useEffect(() => {
    let fetchList: (() => void) | null = () => {
      setDataLoading(true);
      let PAYLOAD_DATA: GetInventoryUsersProps["DATA"] = {
        pageNumber: paginationModel.page + 1,
        pageSize: paginationModel.pageSize,
      };

      if (filters?.isActive) {
        PAYLOAD_DATA["isActive"] = filters?.isActive === "true" ? true : false;
      }

      GetInventoryUsers({
        DATA: PAYLOAD_DATA,
      })
        .then((res) => {
          const data = res?.data;

          if (data?.statusCode === AppStatusCode.api_success) {
            let count = res?.data?.meta?.count;
            if (count && count > 1) {
              if (count > 1) setCount(count);
            } else setCount(0);

            let DATA: any = res?.data?.data;
            DATA = DATA?.map((item: any) => ({
              id: item?._id,
              name: item?.name || "",
              email: item?.email || "",
              phone: item?.phone || "",
              role: item?.role || "",
              isActive: item?.isActive,
            }));
            setData(DATA);
          } else {
            setData([]);
            setCount(0);
          }
        })
        .catch(() => {
          setData([]);
          setCount(0);
        })
        .finally(() => {
          setDataLoading(false);
        });
    };
    fetchList();
    return () => {
      fetchList = null;
    };
  }, [paginationModel, refresh, filters]);

  return (
    <>
      <Grid container spacing={1} justifyContent="flex-end">
        <Grid item xs={12} sm={6}>
          <Box className="tw-flex tw-justify-end tw-items-center">
            <Button
              onClick={toggleForm}
              variant="outlined"
              startIcon={<AddIcon />}
              disableElevation
            >
              <Box component="span" className="tw-line-clamp-1">
                Add New User
              </Box>
            </Button>
            <InventoryUsersFilter
              open={openFilter}
              onClose={toggleFilter}
              filters={filters}
              setFilters={setFilters}
              setOpen={setOpenFilter}
            />
          </Box>
        </Grid>
      </Grid>

      <InventoryUsersTable
        data={data}
        loading={dataLoading}
        count={count}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
      />

      <CustomDrawer open={openForm} onClose={toggleForm} title="Add New User">
        <InventoryUsersForm
          handleRefresh={handleRefresh}
          onClose={toggleForm}
        />
      </CustomDrawer>
    </>
  );
};

export default InventoryUsersLayout;
