import { FormInputProps } from "_interfaces/_common/forms";
import { SideDrawerProps } from "_interfaces/_common/sideDrawer";
import {
  ALLOWED_COUPON_APPLY_ON,
  ALLOWED_COUPON_CATEGORY,
  ALLOWED_COUPON_DISCOUNT_TYPE,
  ALLOWED_COUPON_STATUS,
} from "_interfaces/functions/http-requests/coupon";
import { CouponFilterModel } from "_models/data/coupon/data.coupon.model";
import { generateAutocompleteOptions } from "functions/helper";
import { CustomFilter } from "parts/customFilter";
import React, { useMemo } from "react";

interface InputListProps extends FormInputProps {
  name: "status" | "category" | "applyOn" | "discountType" | "maxUse";
}
interface Props extends SideDrawerProps {
  filters: CouponFilterModel;
  setFilters: React.Dispatch<React.SetStateAction<CouponFilterModel>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CouponFilter: React.FC<Props> = ({
  open,
  setOpen,
  filters,
  setFilters,
}) => {
  const inputList: InputListProps[] = useMemo(
    () => [
      {
        type: "autoComplete",
        name: "status",
        label: "Filter Status",
        placeholder: "Select Status",
        options: generateAutocompleteOptions(ALLOWED_COUPON_STATUS),
        grid: 12,
        multiple: false,
      },
      {
        type: "autoComplete",
        name: "category",
        label: "Category",
        placeholder: "Select Category",
        options: generateAutocompleteOptions(ALLOWED_COUPON_CATEGORY),
        multiple: false,
        grid: 12,
      },
      {
        type: "autoComplete",
        name: "applyOn",
        label: "Apply On",
        placeholder: "Select Apply On",
        options: generateAutocompleteOptions(ALLOWED_COUPON_APPLY_ON),
        multiple: false,
        grid: 12,
      },
      {
        type: "autoComplete",
        name: "discountType",
        label: "Discount Type",
        placeholder: "Select Discount Type",
        options: generateAutocompleteOptions(ALLOWED_COUPON_DISCOUNT_TYPE),
        multiple: false,
        grid: 12,
      },
      {
        type: "text",
        name: "maxUse",
        label: "Max Use",
        placeholder: "Max Use",
        grid: 12,
      },
    ],
    []
  );

  return (
    <>
      <CustomFilter
        filters={filters}
        setFilters={setFilters}
        filterList={inputList}
        open={open as boolean}
        setOpen={setOpen}
      />
    </>
  );
};

export default CouponFilter;
