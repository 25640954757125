import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { GridPaginationModel } from "@mui/x-data-grid";
import { GetAllMsisdnProps } from "_interfaces/functions/http-requests/msisdnManagement";
import MsisdnManagementTableInterface from "_interfaces/msisdn-management/msisdnManagementTable";
import { MsisdnManagementFilterModel } from "_models/data/msisdnManagement/data.msisdn-management-filter.model";
import { AppStatusCode } from "config/appStatusCode";
import { filterNonNullValues } from "functions/helper";
import {
  GetAllMsisdn,
  GetAllMsisdnCount,
} from "functions/http-requests/msisdnManagement";
import CustomDrawer from "parts/customDialog/customDrawer";
import FilterButton from "parts/filterButton";
import SectionSearch from "parts/sectionSearch";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toggleLoading } from "state/reducers/loading";
import MsisdnManagementFilter from "./msisdnManagementFilter";
import MsisdnManagementTable from "./msisdnManagementTable";
import TopStatistics from "./topStatistics";
import UploadMsisdnForm from "./upload-msisdn/uploadMsisdnForm";
import { useSearchParams } from "react-router-dom";

const MsisdnManagementLayout = () => {
  const isInitialRender = useRef(true);
  const Dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const [data, setData] = useState<MsisdnManagementTableInterface["data"]>([]);

  const [openUploadForm, setOpenUploadForm] = useState<boolean>(false);

  const [refresh, setRefresh] = useState<boolean>(false);

  const [searchKey, setSearchKey] = useState<string>(
    searchParams.get("search") || ""
  );

  const [count, setCount] = useState<number>(0);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: Number(searchParams.get("page")) || 0,
    pageSize: Number(searchParams.get("pageSize")) || 10,
  });

  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [filters, setFilters] = useState(() => {
    const initialFilters: MsisdnManagementFilterModel =
      new MsisdnManagementFilterModel();
    for (const [key, value] of searchParams.entries()) {
      if (key !== "page" && key !== "pageSize" && key !== "search") {
        (initialFilters as Record<string, string | number | null>)[key] =
          value || "";
      }
    }
    return initialFilters;
  });

  const [topStatisticsData, setTopStatisticsData] = useState(null);

  const [activeButton, setActiveButton] = useState<string>("");

  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const toggleUpload = () => {
    setOpenUploadForm(!openUploadForm);
  };

  const toggleFilter = () => {
    setOpenFilter(!openFilter);
  };

  const handleRefresh = () => setRefresh(!refresh);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchKey = e.target.value;
    setSearchKey(newSearchKey);
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);
      updatedParams.set("search", newSearchKey);
      return updatedParams;
    });
  };

  const handleClearSearch = () => {
    setSearchKey("");
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);
      // Remove the 'searchKey' parameter from the URL if it exists
      updatedParams.delete("search");
      return updatedParams;
    });
  };

  const handleSubmitSearch = () => {
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);
      updatedParams.set("search", searchKey);
      updatedParams.set("page", "0"); // Reset to the first page on search submit
      updatedParams.set("pageSize", `${paginationModel.pageSize}`);
      return updatedParams;
    });
    isInitialRender.current = false;
    handleRefresh();
  };

  const handleButtonClick = (id: string, filterName: string) => {
    if (id && filterName) {
      if (id === "all") {
        setFilters(new MsisdnManagementFilterModel());
      } else {
        setFilters({
          ...new MsisdnManagementFilterModel(),
          [filterName]: id,
        });
      }
      setActiveButton(id);
    }
  };

  const handleClearActiveButton = () => {
    if (activeButton) {
      setFilters(new MsisdnManagementFilterModel());
      setActiveButton("");
    }
  };

  useEffect(() => {
    const search = searchParams.get("search") || "";
    setSearchKey(search);
  }, [searchParams]);

  useEffect(() => {
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);

      updatedParams.set("page", `${paginationModel.page}`);
      updatedParams.set("pageSize", `${paginationModel.pageSize}`);

      // If the filters object is empty, clear all filter parameters from the URL
      if (Object.keys(filters).length === 0) {
        // Remove all filter-related keys from the URL
        Array.from(updatedParams.keys()).forEach((key) => {
          if (key !== "search" && key !== "page" && key !== "pageSize") {
            updatedParams.delete(key);
          }
        });
      } else {
        // Update filters in the URL
        Object.entries(filters).forEach(([key, value]) => {
          if (value) {
            updatedParams.set(key, String(value));
          } else {
            updatedParams.delete(key);
          }
        });
      }

      return updatedParams;
    });
  }, [filters, paginationModel, setSearchParams]);

  useEffect(() => {
    let fetchList: (() => void) | null = () => {
      setDataLoading(true);
      let PAYLOAD_DATA: GetAllMsisdnProps["DATA"] = {
        searchKey: searchKey,
        status: filters.status,
        isFixed:
          filters?.isFixed?.toLowerCase() === "true"
            ? true
            : filters?.isFixed?.toLowerCase() === "false"
            ? false
            : "",
        type: filters.type,
        pageNumber: paginationModel.page + 1,
        pageSize: paginationModel.pageSize,
      };

      let FILTERED_PAYLOAD_DATA = filterNonNullValues(PAYLOAD_DATA);

      GetAllMsisdn({
        DATA: FILTERED_PAYLOAD_DATA,
      })
        .then((res) => {
          const data = res?.data;

          if (data?.statusCode === AppStatusCode.api_success) {
            let count = res?.data?.meta?.count;
            if (count && count > 1) {
              if (count > 1) setCount(count);
            } else setCount(0);

            let DATA: any = res?.data?.data;
            DATA = DATA?.map((item: any) => ({
              id: item?._id,
              MSISDN: item?.MSISDN || "",
              phoneNumber: item?.phoneNumber || "",
              type: item?.type,
              isFixed: item?.isFixed || "",
              status: item?.status || "",
            }));
            setData(DATA);
          } else {
            setData([]);
            setCount(0);
          }
        })
        .catch(() => {
          setData([]);
          setCount(0);
        })
        .finally(() => {
          setDataLoading(false);
        });
    };
    fetchList();
    return () => {
      fetchList = null;
    };
  }, [paginationModel, refresh, filters, searchKey]);

  useEffect(() => {
    if (!isInitialRender.current) {
      if (!searchKey) handleRefresh();
    }
  }, [searchKey]);

  useEffect(() => {
    let fetchList: (() => void) | null = () => {
      Dispatch(toggleLoading(true));
      GetAllMsisdnCount()
        .then((res) => {
          // if (res?.data === AppStatusCode.api_success) {
          const DATA = res?.data?.data;
          const formattedData: any = {
            total: DATA?.total || 0,
          };

          DATA?.byStatus?.forEach((item: any) => {
            formattedData[item?._id] = item?.count;
          });
          setTopStatisticsData(formattedData);
          // }
        })
        .catch(() => {})
        .finally(() => Dispatch(toggleLoading(false)));
    };
    fetchList();
    return () => {
      fetchList = null;
    };
  }, [Dispatch]);

  const buttonsList = [
    {
      id: "all",
      label: "All",
      filterName: "status",
    },
    {
      id: "active",
      label: "Active",
      filterName: "status",
    },
    { id: "idle", label: "Idle", filterName: "status" },
    { id: "premium", label: "Premium", filterName: "type" },
  ];

  const isFilterApplied = () => {
    return (
      Object.keys(filters).some((key) => (filters as any)[key]) && !activeButton
    );
  };

  return (
    <>
      <TopStatistics data={topStatisticsData} />
      <Box pt={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} className="tw-flex tw-items-center">
            <Box sx={{ flex: "1 1 auto" }}>
              <SectionSearch
                name="search_msisdn"
                value={searchKey}
                onChange={handleSearch}
                onClear={handleClearSearch}
                onSubmit={handleSubmitSearch}
              />
            </Box>
            <FilterButton
              onClick={() => setOpenFilter(true)}
              isFilterApplied={!isFilterApplied()}
            />
          </Grid>
          <Grid item xs={12} sm={6} className="tw-flex tw-justify-end">
            <Button
              variant="outlined"
              className="tw-mr-[12px]"
              onClick={toggleUpload}
            >
              Upload MSISDN
            </Button>
            <Button variant="contained">Manage Stock</Button>
          </Grid>
          <Grid
            container
            item
            xs={12}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              {buttonsList?.map(({ id, label, filterName }, i) => (
                <Button
                  key={i}
                  variant="outlined"
                  color={activeButton === id ? "primary" : "secondary"}
                  className="tw-ml-[8px]"
                  onClick={() => handleButtonClick(id, filterName)}
                >
                  {label}
                </Button>
              ))}
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                className="tw-ml-[8px]"
                disabled={selectedRows.length == 0}
              >
                Change Type
              </Button>
              <Button
                variant="outlined"
                className="tw-ml-[8px]"
                disabled={selectedRows.length == 0}
              >
                Blocked
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box pt={3}>
        <MsisdnManagementTable
          data={data}
          loading={dataLoading}
          count={count}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          setSelectedRows={setSelectedRows}
        />
      </Box>

      <MsisdnManagementFilter
        open={openFilter}
        onClose={toggleFilter}
        filters={filters}
        setFilters={setFilters}
        handleClearActiveButton={handleClearActiveButton}
        activeButton={activeButton}
      />

      <CustomDrawer
        open={openUploadForm}
        onClose={toggleUpload}
        title="Upload Carton"
      >
        <UploadMsisdnForm onClose={toggleUpload} />
      </CustomDrawer>
    </>
  );
};

export default MsisdnManagementLayout;
