import { Box, Grid } from "@mui/material";
import { GridPaginationModel } from "@mui/x-data-grid";
import {
  GLOBAL_ACTIVITY_LOGS_DATA,
  GLOBAL_ACTIVITY_LOGS_PROPS,
} from "_interfaces/global-activity-logs";
import { GlobalActivityLogFilterModel } from "_models/data/global-activity-logs/data.global-activity-logs-filter.model";
import { AppStatusCode } from "config/appStatusCode";
import { GetGlobalActivityLogsList } from "functions/http-requests/global-activity-logs";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import GlobalActivityLogsTable from "./globalActivityLogsTable";
import SectionSearch from "parts/sectionSearch";
import GlobalActivityLogsFilter from "./global_activity_logs_filter";
import { useSearchParams } from "react-router-dom";

export const GLOBAL_ACTIVITY_LOGS_LAYOUT = () => {
  const isInitialRender = useRef(true);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchKey, setSearchKey] = useState<string>(
    searchParams.get("search") || ""
  );
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: Number(searchParams.get("page")) || 0,
    pageSize: Number(searchParams.get("pageSize")) || 10,
  });
  const [count, setCount] = useState<number>(0);
  const [data, setData] = useState<GLOBAL_ACTIVITY_LOGS_DATA[]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [globalActivityFilters, setGlobalActivityFilters] = useState(() => {
    const initialFilters: GlobalActivityLogFilterModel =
      new GlobalActivityLogFilterModel();
    for (const [key, value] of searchParams.entries()) {
      if (key !== "page" && key !== "pageSize" && key !== "search") {
        (initialFilters as Record<string, string | number | null>)[key] =
          value || "";
      }
    }
    return initialFilters;
  });
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const handleRefresh = () => setRefresh(!refresh);
  const toggleFilter = () => {
    setOpenFilter(!openFilter);
  };

  const handleClearSearch = () => {
    setSearchKey("");
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);
      // Remove the 'searchKey' parameter from the URL if it exists
      updatedParams.delete("search");
      return updatedParams;
    });
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchKey = e.target.value;
    setSearchKey(newSearchKey);
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);
      updatedParams.set("search", newSearchKey);
      return updatedParams;
    });
  };

  const handleSubmitSearch = () => {
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);
      updatedParams.set("search", searchKey);
      updatedParams.set("page", "0"); // Reset to the first page on search submit
      updatedParams.set("pageSize", `${paginationModel.pageSize}`);
      return updatedParams;
    });
    isInitialRender.current = false;
    handleRefresh();
  };

  useEffect(() => {
    const search = searchParams.get("search") || "";
    setSearchKey(search);
  }, [searchParams]);

  useEffect(() => {
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);

      updatedParams.set("page", `${paginationModel.page}`);
      updatedParams.set("pageSize", `${paginationModel.pageSize}`);

      // If the filters object is empty, clear all filter parameters from the URL
      if (Object.keys(globalActivityFilters).length === 0) {
        // Remove all filter-related keys from the URL
        Array.from(updatedParams.keys()).forEach((key) => {
          if (key !== "search" && key !== "page" && key !== "pageSize") {
            updatedParams.delete(key);
          }
        });
      } else {
        // Update filters in the URL
        Object.entries(globalActivityFilters).forEach(([key, value]) => {
          if (value) {
            updatedParams.set(key, String(value));
          } else {
            updatedParams.delete(key);
          }
        });
      }

      return updatedParams;
    });
  }, [globalActivityFilters, paginationModel, setSearchParams]);

  useEffect(() => {
    let fetchList: (() => void) | null = () => {
      setDataLoading(true);
      let PAYLOAD_DATA: GLOBAL_ACTIVITY_LOGS_PROPS["DATA"] = {
        pageNumber: (paginationModel.page + 1).toString(),
        pageSize: paginationModel.pageSize.toString(),
        moduleName: globalActivityFilters.moduleName || "",
        searchKey: searchKey || "",
        level: globalActivityFilters.level || "",
        alertLevel: globalActivityFilters.alertLevel || "",
        actionFromServer: globalActivityFilters.actionFromServer || "",
        actionToServer: globalActivityFilters.actionToServer || "",
        activity: globalActivityFilters.activity || "",
        fromDate: globalActivityFilters?.fromDate
          ? moment(globalActivityFilters.fromDate).format("YYYY/MM/DD")
          : "",
        toDate: globalActivityFilters?.toDate
          ? moment(globalActivityFilters.toDate).format("YYYY/MM/DD")
          : "",
      };

      GetGlobalActivityLogsList({
        DATA: PAYLOAD_DATA,
      })
        .then((res) => {
          const data = res?.data;
          if (data?.statusCode === AppStatusCode.api_success) {
            let count = res?.data?.meta?.count[0]?.count;
            if (count && count > 1) {
              if (count > 1) setCount(count);
            } else setCount(0);

            let DATA: any = res?.data?.data;
            DATA = DATA?.map((item: any) => ({
              id: item?._id,
              activity: item?.activity || "",
              moduleName: item?.moduleName || "",
              message: item?.message || "",
              MSISDN: item?.MSISDN || "",
              activityId: item?.activityId || "",
              time: item?.time,
              level: item?.level,
              alertLevel: item?.alertLevel,
              createdAt: item?.createdAt,
            }));
            setData(DATA);
          } else {
            setData([]);
            setCount(0);
          }
        })
        .catch(() => {
          setData([]);
          setCount(0);
        })
        .finally(() => {
          setDataLoading(false);
        });
    };
    fetchList();
    return () => {
      fetchList = null;
    };
  }, [paginationModel, globalActivityFilters, refresh, searchKey]);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} className="tw-flex tw-items-center">
          <Box sx={{ flex: "1 1 auto" }}>
            <SectionSearch
              name="search_regional_distributor"
              value={searchKey}
              onChange={handleSearch}
              onClear={handleClearSearch}
              onSubmit={handleSubmitSearch}
            />
          </Box>
          <GlobalActivityLogsFilter
            open={openFilter}
            onClose={toggleFilter}
            filters={globalActivityFilters}
            setFilters={setGlobalActivityFilters}
            setOpen={setOpenFilter}
          />
        </Grid>
      </Grid>

      <GlobalActivityLogsTable
        data={data}
        loading={dataLoading}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        count={count}
      />
    </>
  );
};
