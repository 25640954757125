import {
  MakeDefaultServerProps,
  UpdateServerProps,
} from "_interfaces/functions/http-requests/server";
import {
  GET_ALL_SERVER,
  MAKE_DEFAULT_SERVER,
  UPDATE_SERVER,
} from "config/endpoint";
import { HTTP } from "functions/http";

export const GetAllServer = () => {
  return HTTP({
    Method: "GET",
    Url: GET_ALL_SERVER,
  });
};

export const makeDefaultServer = ({ DATA }: MakeDefaultServerProps) => {
  return HTTP({
    Method: "POST",
    Url: MAKE_DEFAULT_SERVER,
    Data: DATA,
  });
};
export const updateServer = ({ DATA }: UpdateServerProps) => {
  return HTTP({
    Method: "POST",
    Url: UPDATE_SERVER,
    Data: DATA,
  });
};
