import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { GridPaginationModel } from "@mui/x-data-grid";
import CouponTableInterface from "_interfaces/coupon/couponTable";
import { GetAllCouponProps } from "_interfaces/functions/http-requests/coupon";
import { CouponFilterModel } from "_models/data/coupon/data.coupon.model";
import CouponTable from "component/coupon/couponTable";
import { AppStatusCode } from "config/appStatusCode";
import { filterNonNullValues } from "functions/helper";
import { GetAllCoupon } from "functions/http-requests/coupon";
import CustomDrawer from "parts/customDialog/customDrawer";
import SectionSearch from "parts/sectionSearch";
import { useEffect, useRef, useState } from "react";
import CouponFilter from "./couponFilter";
import CouponForm from "./couponForm";
import { useSearchParams } from "react-router-dom";

const CouponLayout = () => {
  const isInitialRender = useRef(true);

  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const [data, setData] = useState<CouponTableInterface["data"]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);

  const [openForm, setOpenForm] = useState<boolean>(false);
  const [editId, setEditId] = useState<string>("");

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchKey, setSearchKey] = useState<string>(
    searchParams.get("search") || ""
  );

  const [count, setCount] = useState<number>(0);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: Number(searchParams.get("page")) || 0,
    pageSize: Number(searchParams.get("pageSize")) || 10,
  });

  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [filters, setFilters] = useState(() => {
    const initialFilters: CouponFilterModel = new CouponFilterModel();
    for (const [key, value] of searchParams.entries()) {
      if (key !== "page" && key !== "pageSize" && key !== "search") {
        (initialFilters as Record<string, string | number | null>)[key] =
          value || "";
      }
    }
    return initialFilters;
  });

  const toggleForm = () => {
    if (editId) return setEditId("");
    setOpenForm(!openForm);
  };

  const toggleFilter = () => {
    setOpenFilter(!openFilter);
  };

  const handleRefresh = () => setRefresh(!refresh);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchKey = e.target.value;
    setSearchKey(newSearchKey);
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);
      updatedParams.set("search", newSearchKey);
      return updatedParams;
    });
  };

  const handleSubmitSearch = () => {
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);
      updatedParams.set("search", searchKey);
      updatedParams.set("page", "0"); // Reset to the first page on search submit
      updatedParams.set("pageSize", `${paginationModel.pageSize}`);
      return updatedParams;
    });
    isInitialRender.current = false;
    handleRefresh();
  };

  const handleClearSearch = () => {
    setSearchKey("");
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);
      // Remove the 'searchKey' parameter from the URL if it exists
      updatedParams.delete("search");
      return updatedParams;
    });
  };

  useEffect(() => {
    const search = searchParams.get("search") || "";
    setSearchKey(search);
  }, [searchParams]);

  useEffect(() => {
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);

      updatedParams.set("page", `${paginationModel.page}`);
      updatedParams.set("pageSize", `${paginationModel.pageSize}`);

      // If the filters object is empty, clear all filter parameters from the URL
      if (Object.keys(filters).length === 0) {
        // Remove all filter-related keys from the URL
        Array.from(updatedParams.keys()).forEach((key) => {
          if (key !== "search" && key !== "page" && key !== "pageSize") {
            updatedParams.delete(key);
          }
        });
      } else {
        // Update filters in the URL
        Object.entries(filters).forEach(([key, value]) => {
          if (value) {
            updatedParams.set(key, String(value));
          } else {
            updatedParams.delete(key);
          }
        });
      }

      return updatedParams;
    });
  }, [filters, paginationModel, setSearchParams]);

  useEffect(() => {
    let fetchList: (() => void) | null = () => {
      setDataLoading(true);

      let PAYLOAD_DATA: GetAllCouponProps["DATA"] = {
        searchKey: searchKey,
        pageNumber: paginationModel.page + 1,
        pageSize: paginationModel.pageSize,
        status: filters?.status || undefined,
        category: filters?.category || undefined,
        applyOn: filters?.applyOn || undefined,
        discountType: filters?.discountType || undefined,
        maxUse: filters?.maxUse ? Number(filters.maxUse) : undefined,
      };

      PAYLOAD_DATA = filterNonNullValues(PAYLOAD_DATA);

      GetAllCoupon({
        DATA: PAYLOAD_DATA,
      })
        .then((res) => {
          const data = res?.data;

          if (data?.statusCode === AppStatusCode.api_success) {
            let count = res?.data?.meta?.count;
            if (count && count > 1) {
              if (count > 1) setCount(count);
            } else setCount(0);

            let DATA: any = res?.data?.data;
            DATA = DATA?.map((item: any) => ({
              id: item?._id,
              category: item?.category,
              applyOn: item?.applyOn,
              couponCode: item?.couponCode,
              validFrom: item?.validFrom,
              validUntil: item?.validUntil,
              maxUse: item?.maxUse,
              totalUsed: item?.totalUsed,
              status: item?.status,
              isOpen: item?.isOpen,
              title: item?.title,
            }));
            setData(DATA);
          } else {
            setData([]);
            setCount(0);
          }
        })
        .catch(() => {
          setData([]);
          setCount(0);
        })
        .finally(() => {
          setDataLoading(false);
        });
    };
    fetchList();
    return () => {
      fetchList = null;
    };
  }, [paginationModel, refresh, filters]);

  useEffect(() => {
    if (!isInitialRender.current) {
      if (!searchKey) handleRefresh();
    }
  }, [searchKey]);

  return (
    <>
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item xs={12} sm={6} className="tw-flex tw-items-center">
          <Box sx={{ flex: "1 1 auto" }}>
            <SectionSearch
              name="search_coupon"
              value={searchKey}
              onChange={handleSearch}
              onClear={handleClearSearch}
              onSubmit={handleSubmitSearch}
            />
          </Box>
          <CouponFilter
            open={openFilter}
            onClose={toggleFilter}
            filters={filters}
            setFilters={setFilters}
            setOpen={setOpenFilter}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className="tw-flex tw-justify-end tw-items-center">
            <Button
              onClick={toggleForm}
              variant="outlined"
              startIcon={<AddIcon />}
              disableElevation
            >
              <Box component="span" className="tw-line-clamp-1">
                Add Coupon
              </Box>
            </Button>
          </Box>
        </Grid>
      </Grid>

      <CouponTable
        data={data}
        loading={dataLoading}
        setEditId={setEditId}
        count={count}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
      />

      <CustomDrawer
        open={openForm || editId ? true : false}
        onClose={toggleForm}
        title={editId ? "Edit  Coupon Details" : "Add New Coupon"}
      >
        <CouponForm
          handleRefresh={handleRefresh}
          onClose={toggleForm}
          editId={editId}
        />
      </CustomDrawer>
    </>
  );
};

export default CouponLayout;
