import { FormInputProps } from "_interfaces/_common/forms";
import { SideDrawerProps } from "_interfaces/_common/sideDrawer";
import React, { useMemo } from "react";
import { RegionalDistributorsFilterModel } from "_models/data/regional-distributors/data.regional-distributors-filter.model";
import { CustomFilter } from "parts/customFilter";

interface InputListProps extends FormInputProps {
  name: "status";
}
interface Props extends SideDrawerProps {
  filters: RegionalDistributorsFilterModel;
  setFilters: React.Dispatch<
    React.SetStateAction<RegionalDistributorsFilterModel>
  >;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const RegionalDistributorsFilter: React.FC<Props> = ({
  open,
  setOpen,
  filters,
  setFilters,
}) => {
  const inputList: InputListProps[] = useMemo(
    () => [
      {
        type: "autoComplete",
        name: "status",
        label: "Filter by Status",
        placeholder: "Select Status",
        options: [
          {
            id: "true",
            value: "true",
            title: "Active",
            isActive: true,
          },
          {
            id: "false",
            value: "false",
            title: "In-active",
            isActive: true,
          },
        ],
        multiple: false,
      },
    ],
    []
  );

  return (
    <>
      <CustomFilter
        filters={filters}
        setFilters={setFilters}
        filterList={inputList}
        open={open as boolean}
        setOpen={setOpen}
      />
    </>
  );
};

export default RegionalDistributorsFilter;
