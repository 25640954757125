import {
  AutoAssignTaskProps,
  CreateTeamProps,
  RemoveAutoAssignTaskProps,
} from "_interfaces/functions/http-requests/teams";
import {
  AUTO_ASSIGN_TASK_TO_TEAM,
  CREATE_TEAM,
  GET_ALL_TEAM,
  GET_TEAM_DETAILS,
  REMOVE_ASSIGN_TASK_TO_TEAM,
} from "config/endpoint";
import { HTTP } from "functions/http";

export const CreateTeam = ({ DATA }: CreateTeamProps) => {
  return HTTP({
    Method: "POST",
    Url: CREATE_TEAM,
    Data: DATA,
    isFormData: true,
  });
};

export const GetAllTeam = ({ DATA }: CreateTeamProps) => {
  return HTTP({
    Method: "POST",
    Url: GET_ALL_TEAM,
    Data: DATA,
    isFormData: true,
  });
};

export const AutoAssignTask = ({ DATA }: AutoAssignTaskProps) => {
  return HTTP({
    Method: "POST",
    Url: AUTO_ASSIGN_TASK_TO_TEAM,
    Data: DATA,
  });
};
export const RemoveAssignTask = ({ DATA }: RemoveAutoAssignTaskProps) => {
  return HTTP({
    Method: "POST",
    Url: REMOVE_ASSIGN_TASK_TO_TEAM,
    Data: DATA,
  });
};

export const GetTeamDetails = (ID: string) => {
  return HTTP({
    Method: "GET",
    Url: GET_TEAM_DETAILS(ID),
  });
};
