import { FormInputProps } from "_interfaces/_common/forms";
import { SideDrawerProps } from "_interfaces/_common/sideDrawer";
import { InventoryUsersFilterModel } from "_models/data/inventory-users/data.inventory-users-filter.model";
import { CustomFilter } from "parts/customFilter";
import React, { useMemo } from "react";

interface InputListProps extends FormInputProps {
  name: "isActive";
}
interface Props extends SideDrawerProps {
  filters: InventoryUsersFilterModel;
  setFilters: React.Dispatch<React.SetStateAction<InventoryUsersFilterModel>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const InventoryUsersFilter: React.FC<Props> = ({
  open,
  filters,
  setFilters,
  setOpen,
}) => {
  const inputList: InputListProps[] = useMemo(
    () => [
      {
        type: "autoComplete",
        name: "isActive",
        label: "Filter by status",
        placeholder: "Select status",
        options: [
          { id: "true", value: "true", title: "Active", isActive: true },
          { id: "false", value: "false", title: "Inactive", isActive: true },
        ],
        multiple: false,
      },
    ],
    []
  );

  return (
    <>
      <CustomFilter
        filters={filters}
        setFilters={setFilters}
        filterList={inputList}
        open={open as boolean}
        setOpen={setOpen}
      />
    </>
  );
};

export default InventoryUsersFilter;
