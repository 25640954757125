import { generateAutocompleteOptions } from "functions/helper";
import React, { useMemo } from "react";
import { FormInputProps } from "_interfaces/_common/forms";
import { SideDrawerProps } from "_interfaces/_common/sideDrawer";
import { AgentsFilterModel } from "_models/data/agent/data.agents-filter.model";
import { CustomFilter } from "parts/customFilter";

interface InputListProps extends FormInputProps {
  name:
    | "kycStatus"
    | "serviceStatus"
    | "customerType"
    | "identityType"
    | "dateRange";
}
interface Props extends SideDrawerProps {
  filters: AgentsFilterModel;
  setFilters: React.Dispatch<React.SetStateAction<AgentsFilterModel>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const AgentsFilter: React.FC<Props> = ({
  open,
  setOpen,
  filters,
  setFilters,
}) => {
  const inputList: any[] = useMemo(
    () => [
      {
        type: "autoComplete",
        name: "type",
        label: "Filter by agent type",
        placeholder: "Select agent type",
        options: generateAutocompleteOptions(["dealer", "super_agent"]),
        multiple: false,
      },
      {
        type: "autoComplete",
        name: "activeStatus",
        label: "Filter by Active status",
        placeholder: "Select Active status",
        options: generateAutocompleteOptions(["inActive", "active"]),
        multiple: false,
      },
      {
        type: "autoComplete",
        name: "approveStatus",
        label: "Filter by Approve status",
        placeholder: "Select Approve status",
        options: generateAutocompleteOptions(["approve", "notApprove"]),
        multiple: false,
      },
    ],
    []
  );

  return (
    <>
      <CustomFilter
        filters={filters}
        setFilters={setFilters}
        filterList={inputList}
        open={open as boolean}
        setOpen={setOpen}
      />
    </>
  );
};

export default AgentsFilter;
