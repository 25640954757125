import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { AppStatusCode } from "config/appStatusCode";
import { HTTP_ERROR } from "functions/http";
import {
  GetAppliedCouponListInfluencerById,
  GetInfluencerById,
} from "functions/http-requests/influencer";
import { RouteConstant } from "navigation/constant";
import CustomDrawer from "parts/customDialog/customDrawer";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate, useSearchParams } from "react-router-dom";
import { setAlert } from "state/reducers/alert";
import { toggleLoading } from "state/reducers/loading";
import InfluencerDetailsInterface, {
  AppliedCouponListByInfluencerInterface,
} from "_interfaces/influencer/details";
import InfluencerForm from "../influencerForm";
import GenerateCouponLinkForm from "./generateCouponLinkForm";
import InfluencerCouponDetailsTable from "./InfluencerCouponDetailsTable";
import InfluencerCouponLinksTable from "./influencerCouponLinksTable";
import InfluencerDetailsTable from "./InfluencerDetailsTable";
import { AppliedCouponFilterModel } from "_models/data/influencer/data.applied-coupon-filter.model";
import AppliedCouponTableInterface from "_models/data/influencer/data.applied-coupon-model";
import { GridPaginationModel } from "@mui/x-data-grid";
import { AppliedCouponListProps } from "_interfaces/functions/http-requests/influencer";
import { filterNonNullValues } from "functions/helper";
import AppliedCouponsTable from "./appliedCouponsTable";
import AppliedCouponFilter from "./appliedCouponsFilter";
import { Card, Typography } from "@mui/material";

interface Props {
  id: string;
}

const InfluencerDetailsLayout: React.FC<Props> = ({ id }) => {
  const Dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [state, setState] = useState<InfluencerDetailsInterface | null>(null);
  const [appliedCouponList, setAppliedCouponList] = useState<
    AppliedCouponTableInterface["data"]
  >([]);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: Number(searchParams.get("page")) || 0,
    pageSize: Number(searchParams.get("pageSize")) || 10,
  });
  const [count, setCount] = useState<number>(0);
  const [filters, setFilters] = useState(() => {
    const initialFilters: AppliedCouponFilterModel =
      new AppliedCouponFilterModel();
    for (const [key, value] of searchParams.entries()) {
      if (key !== "page" && key !== "pageSize" && key !== "search") {
        (initialFilters as Record<string, string | number | null>)[key] =
          value || "";
      }
    }
    return initialFilters;
  });
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [openGenerateLinkForm, setOpenGenerateLinkForm] =
    useState<boolean>(false);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const toggleFilter = () => {
    setOpenFilter(!openFilter);
  };

  const [refresh, setRefresh] = useState<boolean>(false);

  const handleRefresh = () => setRefresh(!refresh);

  const toggleForm = () => {
    setOpenForm(!openForm);
  };
  const toggleGenerateLinkForm = () =>
    setOpenGenerateLinkForm(!openGenerateLinkForm);

  let handleFetchById = (ID: string) => {
    Dispatch(toggleLoading(true));
    GetInfluencerById(ID)
      .then((res: any) => {
        const data = res?.data;
        if (
          data?.statusCode === AppStatusCode.api_success ||
          data?.statusCode === AppStatusCode.api_updated
        ) {
          const DATA: any = data?.data;
          const COUPON_DATA = DATA?.couponDetails || {};
          const COUPON_LINKS = DATA?.couponLinks || [];

          setState((prev) => ({
            ...prev,
            id: DATA?._id,
            influencerDetails: {
              influencerID: DATA?.influencerID,
              email: DATA?.email,
              phoneNumber: DATA?.phoneNumber,
              category: DATA?.category,
              couponId: DATA?.couponId,
              firstName: DATA?.firstName,
              middleName: DATA?.middleName,
              lastName: DATA?.lastName,
              region: DATA?.region,
              district: DATA?.district,
              county: DATA?.county,
              subCounty: DATA?.subCounty,
              parish: DATA?.parish,
              isActive: DATA?.isActive,
            },
            couponDetails: {
              id: COUPON_DATA?._id,
              category: COUPON_DATA?.category,
              applyOn: COUPON_DATA?.applyOn,
              couponCode: COUPON_DATA?.couponCode,
              validFrom: COUPON_DATA?.validFrom,
              validUntil: COUPON_DATA?.validUntil,
              maxUse: COUPON_DATA?.maxUse,
              discountType: COUPON_DATA?.discountType,
              discountValue: COUPON_DATA?.discountValue,
              minPurchaseAmount: COUPON_DATA?.minPurchaseAmount,
              maxDiscountAmount: COUPON_DATA?.maxDiscountAmount,
              isOpen: COUPON_DATA?.isOpen,
              totalUsed: COUPON_DATA?.totalUsed,
              status: COUPON_DATA?.status,
              info: COUPON_DATA?.info,
              title: COUPON_DATA?.title,
            },
            couponLinks: COUPON_LINKS?.map((item: any) => ({
              id: item?._id,
              linkID: item?.linkID,
              link: item?.link,
            })),
          }));
        } else {
          setState(null);
        }
        setIsLoaded(true);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
          setState(null);
          setIsLoaded(true);
        }
      })
      .finally(() => {
        Dispatch(toggleLoading(false));
      });
  };

  useEffect(() => {
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);

      updatedParams.set("page", `${paginationModel.page}`);
      updatedParams.set("pageSize", `${paginationModel.pageSize}`);

      // If the filters object is empty, clear all filter parameters from the URL
      if (Object.keys(filters).length === 0) {
        // Remove all filter-related keys from the URL
        Array.from(updatedParams.keys()).forEach((key) => {
          if (key !== "search" && key !== "page" && key !== "pageSize") {
            updatedParams.delete(key);
          }
        });
      } else {
        // Update filters in the URL
        Object.entries(filters).forEach(([key, value]) => {
          if (value) {
            updatedParams.set(key, String(value));
          } else {
            updatedParams.delete(key);
          }
        });
      }

      return updatedParams;
    });
  }, [filters, paginationModel, setSearchParams]);

  useEffect(() => {
    if (id) {
      let fetchById: (() => void) | null = () => {
        handleFetchById(id);
      };
      fetchById();
      return () => {
        fetchById = null;
      };
    }
  }, [id, refresh, Dispatch]);

  useEffect(() => {
    let fetchList: (() => void) | null = () => {
      setDataLoading(true);

      let PAYLOAD_DATA: AppliedCouponListProps["DATA"] = {
        pageNumber: paginationModel.page + 1,
        pageSize: paginationModel.pageSize,
        appliedOn: filters.appliedOn || "",
        fromDate: filters?.fromDate || "",
        toDate: filters?.toDate || "",
      };

      PAYLOAD_DATA = filterNonNullValues(PAYLOAD_DATA);

      GetAppliedCouponListInfluencerById(id, PAYLOAD_DATA)
        .then((res) => {
          const data = res?.data;

          if (data?.statusCode === AppStatusCode.api_success) {
            let count = res?.data?.meta?.count[0]?.count;
            if (count && count > 1) {
              if (count > 1) setCount(count);
            } else setCount(0);

            let DATA: any = res?.data?.data;
            DATA = DATA?.map((item: any) => ({
              id: item?._id,
              influencerId: item?.influencerId,
              appliedOn: item?.appliedOn,
              couponCode: item?.couponCode,
              couponId: item?.couponId,
              time: item?.time,
              phoneBookingId: item?.phoneBookingId,
              paymentId: item?.paymentId,
              discountAmount: item?.discountAmount,
              createdAt: item?.createdAt,
            }));
            setAppliedCouponList(DATA);
          } else {
            setAppliedCouponList([]);
            setCount(0);
          }
        })
        .catch(() => {
          setAppliedCouponList([]);
          setCount(0);
        })
        .finally(() => {
          setDataLoading(false);
        });
    };
    fetchList();
    return () => {
      fetchList = null;
    };
  }, [id, refresh, paginationModel, filters]);

  if (id && isLoaded && !state?.id)
    return <Navigate to={RouteConstant.INFLUENCER} />;

  return (
    <>
      <Box pb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} className="tw-flex tw-justify-end" sx={{ gap: 2 }}>
            <Button
              onClick={toggleGenerateLinkForm}
              variant="outlined"
              disableElevation
            >
              <Box component="span" className="tw-line-clamp-1">
                Generate Coupon Link
              </Box>
            </Button>
            <Button variant="contained" onClick={toggleForm}>
              Edit Details
            </Button>
          </Grid>
        </Grid>
      </Box>

      {state?.influencerDetails ? (
        <InfluencerDetailsTable DATA={state?.influencerDetails} />
      ) : (
        <></>
      )}

      {state?.couponDetails ? (
        <InfluencerCouponDetailsTable DATA={state?.couponDetails} />
      ) : (
        <></>
      )}

      {state?.couponLinks?.length ? (
        <InfluencerCouponLinksTable
          data={state?.couponLinks}
          handleRefresh={handleRefresh}
        />
      ) : (
        <></>
      )}

      <Box pt={3}>
        <Card sx={{ padding: "15px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} className="tw-flex tw-items-center">
              <Typography
                color="text.primary"
                className="tw-text-[18px]"
                sx={{ fontWeight: "bold" }}
              >
                {"Applied Coupons"}
              </Typography>
              <AppliedCouponFilter
                open={openFilter}
                onClose={toggleFilter}
                filters={filters}
                setFilters={setFilters}
                setOpen={setOpenFilter}
              />
            </Grid>
          </Grid>
          <AppliedCouponsTable
            data={appliedCouponList}
            loading={dataLoading}
            count={count}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
          />
        </Card>
      </Box>

      <CustomDrawer
        open={openForm && !!state?.id}
        onClose={toggleForm}
        title="Edit Influencer"
      >
        <InfluencerForm
          handleRefresh={handleRefresh}
          onClose={toggleForm}
          editId={state?.id}
        />
      </CustomDrawer>

      <Dialog
        open={openGenerateLinkForm && !!state?.id}
        fullWidth
        maxWidth={"xs"}
        sx={{ minWidth: 220 }}
      >
        <DialogTitle sx={{ fontSize: 24 }}>Coupon Link</DialogTitle>
        <DialogContent>
          <Box pt={3}>
            <GenerateCouponLinkForm
              influencerId={state?.id}
              onClose={toggleGenerateLinkForm}
              handleRefresh={handleRefresh}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default InfluencerDetailsLayout;
