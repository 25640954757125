import { ServerGroupListInterface } from "_interfaces/server";
import { AppStatusCode } from "config/appStatusCode";
import {
  GetAllServer,
  makeDefaultServer,
  updateServer,
} from "functions/http-requests/server";
import { useEffect, useState } from "react";
import { ServerList } from "./serverList";
import {
  MakeDefaultServerProps,
  UpdateServerProps,
} from "_interfaces/functions/http-requests/server";
import { setAlert } from "state/reducers/alert";
import { useDispatch } from "react-redux";
import { toggleLoading } from "state/reducers/loading";

export const ServerLayout = () => {
  const [data, setData] = useState<ServerGroupListInterface[]>([]);
  const Dispatch = useDispatch();

  const handleDefaultServer = (serverName: string, type: string) => {
    if (!serverName || !type) return;

    const props: MakeDefaultServerProps = {
      DATA: {
        serverName: serverName,
        type: type,
      },
    };
    try {
      Dispatch(toggleLoading(true));
      makeDefaultServer(props).then((res) => {
        const result = res?.data;
        if (
          result?.statusCode === AppStatusCode.api_success ||
          result?.statusCode === AppStatusCode.api_updated
        ) {
          Dispatch(setAlert({ type: "success", message: result.message }));
          getAllServerList();
        } else {
          Dispatch(
            setAlert({
              type: "error",
              message: "There is some error to make the default server! ",
            })
          );
        }
      });
    } catch (error) {
      Dispatch(
        setAlert({
          type: "error",
          message: "There is some error to make the default server! ",
        })
      );
    } finally {
      Dispatch(toggleLoading(false));
    }
  };

  const getAllServerList = () => {
    Dispatch(toggleLoading(true));
    GetAllServer()
      .then((res) => {
        const result = res?.data;
        if (
          result?.statusCode === AppStatusCode.api_success ||
          result?.statusCode === AppStatusCode.api_updated
        ) {
          let DATA: any = res?.data?.data;
          let resultedData: ServerGroupListInterface[] = [];
          DATA?.forEach((val: any) => {
            const isPresentData: ServerGroupListInterface | undefined =
              resultedData?.find(
                (serverVal) => serverVal?.serverName == val?.serverName
              );
            if (isPresentData) {
              isPresentData.serverDetails.push({
                id: val?._id,
                type: val?.type,
                url: val?.url,
                isDefault: val?.isDefault,
              });
            } else {
              resultedData.push({
                serverName: val.serverName,
                serverDetails: [
                  {
                    id: val?._id,
                    type: val?.type,
                    url: val?.url,
                    isDefault: val?.isDefault,
                  },
                ],
              });
            }
          });
          setData(resultedData);
        } else {
          setData([]);
        }
      })
      .catch(() => {
        setData([]);
      })
      .finally(() => {
        Dispatch(toggleLoading(false));
      });
  };

  useEffect(() => {
    getAllServerList();
  }, []);

  return (
    <>
      <ServerList
        data={data}
        handleDefaultServer={handleDefaultServer}
        getAllServerList={getAllServerList}
      />
    </>
  );
};
