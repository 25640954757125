import { Card, Typography, Grid, Box } from "@mui/material";
import { GridPaginationModel } from "@mui/x-data-grid";
import { GetAllHandlersProps } from "_interfaces/functions/http-requests/handlers";
import HandlerTableInterface from "_interfaces/handler";
import { HandlersFilterModel } from "_models/data/handler/data.handlers-filter.model";
import HandlerDetailsFilter from "component/agents/details/handlerDetailsFilter";
import { AppStatusCode } from "config/appStatusCode";
import { filterNonNullValues } from "functions/helper";
import { GetAllHandlers } from "functions/http-requests/handlers";
import SectionSearch from "parts/sectionSearch";
import { useEffect, useRef, useState } from "react";
import AllHandlersTable from "./allHandlersTable";
import { useSearchParams } from "react-router-dom";

const HandlersLayout = () => {
  const isInitialRender = useRef(true);
  const [data, setData] = useState<HandlerTableInterface["data"]>([]);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchKey, setSearchKey] = useState<string>(
    searchParams.get("search") || ""
  );
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const handleRefresh = () => setRefresh(!refresh);
  const [filters, setFilters] = useState(() => {
      const initialFilters: HandlersFilterModel = new HandlersFilterModel();
      for (const [key, value] of searchParams.entries()) {
        if (key !== "page" && key !== "pageSize" && key !== "search") {
          (initialFilters as Record<string, string | number | null>)[key] = value || "";
        }
      }
      return initialFilters;
    });
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: Number(searchParams.get("page")) || 0,
    pageSize: Number(searchParams.get("pageSize")) || 10,
  });
  const [totalcount, setTotalCount] = useState<number>(0);
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchKey = e.target.value;
    setSearchKey(newSearchKey);
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);
      updatedParams.set("search", newSearchKey);
      return updatedParams;
    });
  };

  const handleSubmitSearch = () => {
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);
      updatedParams.set("search", searchKey);
      updatedParams.set("page", "0"); // Reset to the first page on search submit
      updatedParams.set("pageSize", `${paginationModel.pageSize}`);
      return updatedParams;
    });
    isInitialRender.current = false;
    handleRefresh();
  };
  const toggleFilter = () => {
    setOpenFilter(!openFilter);
  };
  const handleClearSearch = () => {
    setSearchKey("");
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);
      // Remove the 'searchKey' parameter from the URL if it exists
      updatedParams.delete("search");
      return updatedParams;
    });
  };

  useEffect(() => {
    const search = searchParams.get("search") || "";
    setSearchKey(search);
  }, [searchParams]);

  useEffect(() => {
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);

      updatedParams.set("page", `${paginationModel.page}`);
      updatedParams.set("pageSize", `${paginationModel.pageSize}`);

      // If the filters object is empty, clear all filter parameters from the URL
      if (Object.keys(filters).length === 0) {
        // Remove all filter-related keys from the URL
        Array.from(updatedParams.keys()).forEach((key) => {
          if (key !== "search" && key !== "page" && key !== "pageSize") {
            updatedParams.delete(key);
          }
        });
      } else {
        // Update filters in the URL
        Object.entries(filters).forEach(([key, value]) => {
          if (value) {
            updatedParams.set(key, String(value));
          } else {
            updatedParams.delete(key);
          }
        });
      }

      return updatedParams;
    });
  }, [filters, paginationModel, setSearchParams]);

  useEffect(() => {
    let fetchAllAgentByDistributor: (() => void) | null = () => {
      setDataLoading(true);
      let PAYLOAD_DATA: GetAllHandlersProps["DATA"] = {
        searchKey: searchKey,
        isActive:
          filters.activeStatus == "active"
            ? true
            : filters.activeStatus == "inActive"
            ? false
            : undefined,
        isApprove:
          filters.approveStatus == "approve"
            ? true
            : filters.approveStatus == "notApprove"
            ? false
            : undefined,
        pageNumber: paginationModel.page + 1,
        pageSize: paginationModel.pageSize,
      };

      let FILTERED_PAYLOAD_DATA = filterNonNullValues(PAYLOAD_DATA);
      GetAllHandlers({ DATA: FILTERED_PAYLOAD_DATA })
        .then((res) => {
          const data = res?.data;
          if (data?.statusCode === AppStatusCode.api_success) {
            let count = res?.data?.meta?.count;
            if (count && count > 1) {
              if (count > 1) setTotalCount(count);
            } else setTotalCount(0);
            let DATA: any = res?.data?.data;
            DATA = DATA?.map((item: any) => ({
              id: item?._id,
              name: item?.userDetails?.name || "",
              email: item?.userDetails?.email || "",
              phone: item?.userDetails?.phone || "",
              role: item?.userDetails?.role || "",
              date: item?.createdAt || "",
              isActive: item?.isActive,
            }));
            setData(DATA);
            setTotalCount(res?.data?.meta?.count);
          } else {
            setData([]);
            setTotalCount(0);
          }
        })
        .catch((err) => {
          setData([]);
          setTotalCount(0);
        })
        .finally(() => {
          setDataLoading(false);
        });
    };
    fetchAllAgentByDistributor();
  }, [refresh, paginationModel, filters, searchKey]);

  return (
    <>
      <Card
        sx={{
          padding: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <Typography
          color="text.primary"
          className="tw-text-[18px]"
          sx={{ fontWeight: "bold" }}
        >
          {"Handlers List"}
        </Typography>
        <Grid container spacing={2} sx={{ marginBottom: "1rem" }}>
          <Grid item xs={12} sm={6} className="tw-flex tw-items-center">
            <Box sx={{ flex: "1 1 auto" }}>
              <SectionSearch
                name="search_sim"
                value={searchKey}
                onChange={handleSearch}
                onClear={handleClearSearch}
                onSubmit={handleSubmitSearch}
              />
            </Box>
            <HandlerDetailsFilter
              open={openFilter}
              onClose={toggleFilter}
              filters={filters}
              setFilters={setFilters}
              setOpen={setOpenFilter}
            />
          </Grid>
        </Grid>
        <AllHandlersTable
          data={data}
          loading={dataLoading}
          count={totalcount}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
        />
      </Card>
    </>
  );
};

export default HandlersLayout;
