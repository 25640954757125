interface Props {
  apiModule?: "cbs" | "tag" | "hlr" | "hss" | "nira" | "ucc" | "service" | "sms" | "momo" | "esim" | "";
  level?: "error" | "warning" | "normal" | "";
  alertLevel?: "veryHigh" | "high" | "medium" | "normal" | "";
  fromDate?: Date | null; // YYYY-MM-DD
  toDate?: Date | null; // YYYY-MM-DD
}

const defaultProps: Props = {
  apiModule: "",
  level: "",
  alertLevel: "",
  fromDate: null,
  toDate: null,
};

export class ThirdPartyLogFilterModel {
  apiModule?: "cbs" | "tag" | "hlr" | "hss" | "nira" | "ucc" | "service" | "sms" | "momo" | "esim" | "";
  level?: "error" | "warning" | "normal" | "";
  alertLevel?: "veryHigh" | "high" | "medium" | "normal" | "";
  fromDate?: Date | null; // YYYY-MM-DD
  toDate?: Date | null; // YYYY-MM-DD

  constructor(data: Props = defaultProps) {
    this.apiModule = data.apiModule || "";
    this.level = data.level || "";
    this.alertLevel = data.alertLevel || "";
    this.fromDate = data.fromDate || null;
    this.toDate = data.toDate || null;
  }
}
