import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CustomDateRangePicker from "component/_common/forms/customDateRangePicker";
import InputAutoComplete from "component/_common/forms/inputAutoComplete";
import { generateAutocompleteOptions } from "functions/helper";
import moment from "moment";
import SideDrawer from "parts/sideDrawer";
import React, { Fragment, useMemo } from "react";
import { FormInputProps } from "_interfaces/_common/forms";
import { SideDrawerProps } from "_interfaces/_common/sideDrawer";
import { ThirdPartyLogFilterModel } from "_models/data/third_party_api_alerts/data.third-party-api-filter.model";
import { CustomFilter } from "parts/customFilter";

interface InputListProps extends FormInputProps {
  name:
    | "moduleName"
    | "level"
    | "alertLevel"
    | "dateRange"
    | "actionFromServer"
    | "actionToServer"
    | "activity";
}
interface Props extends SideDrawerProps {
  filters: ThirdPartyLogFilterModel;
  setFilters: React.Dispatch<React.SetStateAction<ThirdPartyLogFilterModel>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const GlobalActivityLogsFilter: React.FC<Props> = ({
  open,
  setOpen,
  filters,
  setFilters,
}) => {
  const inputList: InputListProps[] = useMemo(
    () => [
      {
        type: "autoComplete",
        name: "moduleName",
        label: "Filter by Api Module",
        placeholder: "Select Api Module",
        options: generateAutocompleteOptions([
          "agent",
          "handler",
          "customer",
          "regional_distributer",
          "chanel",
          "SIM",
          "MSISDN",
          "payment",
          "bill",
          "other",
          "tempReg",
        ]),
        multiple: false,
      },
      {
        type: "autoComplete",
        name: "level",
        label: "Filter by Level",
        placeholder: "Select Level",
        options: generateAutocompleteOptions(["error", "warning", "normal"]),
        multiple: false,
      },
      {
        type: "autoComplete",
        name: "alertLevel",
        label: "Filter by Alert Level",
        placeholder: "Select Alert Level",
        options: generateAutocompleteOptions([
          "veryHigh",
          "high",
          "medium",
          "normal",
        ]),
        multiple: false,
      },
      {
        type: "autoComplete",
        name: "activity",
        label: "Filter by Activity",
        placeholder: "Select Activity",
        options: generateAutocompleteOptions([
          "upload_sim",
          "upload_msisdn",
          "create-agent",
          "approve-agent",
          "update-agent",
          "create-handler",
          "update-handler",
          "handler-activation",
          "sim_carton_uploaded",
          "payment_momo",
          "registration_final_submit",
          "DE_REGISTRATION",
          "SIM_SWAP",
          "msisdn_carton_upload",
          "create_wallet",
          "credit_wallet",
          "debit_wallet",
          "hlr_hss_resubmit",
          "registration_resubmit",
          "cbs_resubmit",
          "wallet_pin_change",
          "wallet_recharge",
          "wallet_transaction",
          "block_user",
        ]),
        multiple: false,
      },
      {
        type: "autoComplete",
        name: "actionFromServer",
        label: "Filter by Action From Server",
        placeholder: "Select Action From Server",
        options: generateAutocompleteOptions([
          "tag",
          "inventory",
          "customer_api_service",
        ]),
        multiple: false,
      },
      {
        type: "autoComplete",
        name: "actionToServer",
        label: "Filter by Action To Server",
        placeholder: "Select Action To Server",
        options: generateAutocompleteOptions([
          "tag",
          "inventory",
          "customer_api_service",
        ]),
        multiple: false,
      },
      {
        type: "dateRange",
        name: "dateRange",
        label: "Select Date range",
        placeholder: "Select Date range",
      },
    ],
    []
  );

  return (
    <>
      <CustomFilter
        filters={filters}
        setFilters={setFilters}
        filterList={inputList}
        open={open as boolean}
        setOpen={setOpen}
      />
    </>
  );
};

export default GlobalActivityLogsFilter;
