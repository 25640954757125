import { generateAutocompleteOptions } from "functions/helper";
import React, { useMemo } from "react";
import { FormInputProps } from "_interfaces/_common/forms";
import { SideDrawerProps } from "_interfaces/_common/sideDrawer";
import { RechargeFilterModel } from "_models/data/recharge/data.recharge-filter.model";
import { CustomFilter } from "parts/customFilter";

interface InputListProps extends FormInputProps {
  name: "status" | "rechargeBy" | "rechargeType" | "dateRange";
}
interface Props extends SideDrawerProps {
  filters: RechargeFilterModel;
  setFilters: React.Dispatch<React.SetStateAction<RechargeFilterModel>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const RechargeFilter: React.FC<Props> = ({
  open,
  filters,
  setFilters,
  setOpen,
}) => {
  const inputList: InputListProps[] = useMemo(
    () => [
      {
        type: "autoComplete",
        name: "status",
        label: "Filter by Status",
        placeholder: "Select Status",
        options: generateAutocompleteOptions([
          "success",
          "failed",
          "pending",
          "inProgress",
        ]),
        multiple: false,
      },
      {
        type: "autoComplete",
        name: "rechargeBy",
        label: "Filter by Recharge",
        placeholder: "Select Recharge",
        options: generateAutocompleteOptions([
          "interswitch",
          "momo",
          "self",
          "auto",
        ]),
        multiple: false,
      },
      {
        type: "autoComplete",
        name: "rechargeType",
        label: "Filter by Recharge Type",
        placeholder: "Select Recharge Type",
        options: generateAutocompleteOptions([
          "bundle",
          "airtime",
          "build_bundle",
          "wallet_recharge",
        ]),
        multiple: false,
      },
      {
        type: "dateRange",
        name: "dateRange",
        label: "Select Date range",
        placeholder: "Select Date range",
      },
    ],
    []
  );

  return (
    <>
      <CustomFilter
        filters={filters}
        setFilters={setFilters}
        filterList={inputList}
        open={open as boolean}
        setOpen={setOpen}
      />
    </>
  );
};

export default RechargeFilter;
