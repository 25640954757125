import { GridPaginationModel } from "@mui/x-data-grid";
import { SimSwapFilterProps } from "_interfaces/functions/http-requests/sim-swap";
import { SimSwapFilterModel } from "_models/data/sim-swap/data.sim-swap-filter.model";
import SimSwapTableInterface from "_models/data/sim-swap/data.sim-swap.model";
import { AppStatusCode } from "config/appStatusCode";
import { filterNonNullValues } from "functions/helper";
import { GetSimSwapList } from "functions/http-requests/sim-swap";
import { useState, useEffect, useRef } from "react";
import SimSwapTable from "./simSwapTable";
import SimSwapFilter from "./simSwapFilter";
import { Grid, Box } from "@mui/material";
import SectionSearch from "parts/sectionSearch";
import { useSearchParams } from "react-router-dom";

export const SimSwapLayout = () => {
  const isInitialRender = useRef(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchKey, setSearchKey] = useState<string>(
    searchParams.get("search") || ""
  );
  const [refresh, setRefresh] = useState<boolean>(false);
  const handleRefresh = () => setRefresh(!refresh);
  const handleClearSearch = () => {
    setSearchKey("");
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);
      // Remove the 'searchKey' parameter from the URL if it exists
      updatedParams.delete("search");
      return updatedParams;
    });
  };
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: Number(searchParams.get("page")) || 0,
    pageSize: Number(searchParams.get("pageSize")) || 10,
  });
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [data, setData] = useState<SimSwapTableInterface["data"]>([]);
  const [count, setCount] = useState<number>(0);
  const [filters, setFilters] = useState(() => {
    const initialFilters: SimSwapFilterModel = new SimSwapFilterModel();
    for (const [key, value] of searchParams.entries()) {
      if (key !== "page" && key !== "pageSize" && key !== "search") {
        (initialFilters as Record<string, string | number | null>)[key] =
          value || "";
      }
    }
    return initialFilters;
  });

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchKey = e.target.value;
    setSearchKey(newSearchKey);
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);
      updatedParams.set("search", newSearchKey);
      return updatedParams;
    });
  };

  const handleSubmitSearch = () => {
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);
      updatedParams.set("search", searchKey);
      updatedParams.set("page", "0"); // Reset to the first page on search submit
      updatedParams.set("pageSize", `${paginationModel.pageSize}`);
      return updatedParams;
    });
    isInitialRender.current = false;
    handleRefresh();
  };

  const toggleFilter = () => {
    setOpenFilter(!openFilter);
  };

  useEffect(() => {
    const search = searchParams.get("search") || "";
    setSearchKey(search);
  }, [searchParams]);

  useEffect(() => {
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);

      updatedParams.set("page", `${paginationModel.page}`);
      updatedParams.set("pageSize", `${paginationModel.pageSize}`);

      // If the filters object is empty, clear all filter parameters from the URL
      if (Object.keys(filters).length === 0) {
        // Remove all filter-related keys from the URL
        Array.from(updatedParams.keys()).forEach((key) => {
          if (key !== "search" && key !== "page" && key !== "pageSize") {
            updatedParams.delete(key);
          }
        });
      } else {
        // Update filters in the URL
        Object.entries(filters).forEach(([key, value]) => {
          if (value) {
            updatedParams.set(key, String(value));
          } else {
            updatedParams.delete(key);
          }
        });
      }

      return updatedParams;
    });
  }, [filters, paginationModel, setSearchParams]);

  useEffect(() => {
    let fetchList: (() => void) | null = () => {
      setDataLoading(true);

      let PAYLOAD_DATA: SimSwapFilterProps["DATA"] = {
        searchKey: searchKey,
        pageNumber: paginationModel.page + 1,
        pageSize: paginationModel.pageSize,
        status: filters.status || "",
        fromDate: filters.fromDate,
        toDate: filters.toDate,
      };

      PAYLOAD_DATA = filterNonNullValues(PAYLOAD_DATA);

      GetSimSwapList({ DATA: PAYLOAD_DATA })
        .then((res) => {
          const data = res?.data;

          if (data?.statusCode === AppStatusCode.api_success) {
            let count = res?.data?.meta?.count[0]?.count;
            if (count && count > 1) {
              if (count > 1) setCount(count);
            } else setCount(0);

            let DATA: any = res?.data?.data;
            DATA = DATA?.map((item: any) => ({
              id: item?._id,
              oldICCID: item?.oldICCID,
              MSISDN: item?.MSISDN,
              ICCID: item?.ICCID,
              swapKyc: item?.swapKyc,
            }));
            setData(DATA);
          } else {
            setData([]);
            setCount(0);
          }
        })
        .catch(() => {
          setData([]);
          setCount(0);
        })
        .finally(() => {
          setDataLoading(false);
        });
    };
    fetchList();
    return () => {
      fetchList = null;
    };
  }, [refresh, paginationModel, filters, searchKey]);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} className="tw-flex tw-items-center">
          <Box sx={{ flex: "1 1 auto" }}>
            <SectionSearch
              name="search_regional_distributor"
              value={searchKey}
              onChange={handleSearch}
              onClear={handleClearSearch}
              onSubmit={handleSubmitSearch}
            />
          </Box>
          <SimSwapFilter
            open={openFilter}
            onClose={toggleFilter}
            filters={filters}
            setFilters={setFilters}
            setOpen={setOpenFilter}
          />
        </Grid>
      </Grid>
      <SimSwapTable
        data={data}
        loading={dataLoading}
        count={count}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
      />
    </>
  );
};
